<template>
  <div>
    <b-card
      v-if="showForm"
      no-body
      class="mb-0"
    >
      <b-card-header>
        <b-card-title class="text-gray-800">
          Dados do paciente:
        </b-card-title>
      </b-card-header>
      <b-card-body>
        <validation-observer ref="bariatricRules">
          <b-form>
            <div>
              <b-row>
                <b-col cols="4">
                  <b-form-group
                    label-for="birthdate"
                    label="Data de nascimento"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Data de nascimento"
                      rules="required"
                    >
                      <b-input-group class="input-group-merge">
                        <flat-pickr
                          id="birthdate"
                          v-model="birthdate"
                          class="form-control"
                          :config="configDateBirthdate"
                          placeholder="Selecione"
                        />
                        <b-input-group-append>
                          <b-input-group-text>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-calendar4"
                              viewBox="0 0 16 16"
                            >
                              <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v1h14V3a1 1 0 0 0-1-1H2zm13 3H1v9a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V5z" />
                            </svg>
                          </b-input-group-text>
                        </b-input-group-append>
                      </b-input-group>
                      <small
                        v-if="errors.length > 0"
                        class="text-danger"
                      >{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    label-for="gender"
                    label="Sexo biológico"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Sexo biológico"
                      rules="required"
                    >
                      <v-select
                        v-model="gender"
                        class="style-select"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="genderOptions"
                        :reduce="options => options.code"
                        placeholder="Selecione"
                      />
                      <small
                        v-if="errors.length > 0"
                        class="text-danger"
                      >{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="4">
                  <b-form-group
                    label-for="mainCellPhone"
                  >
                    <div class="d-flex align-items-center">
                      <label
                        style="font-size: 1rem; font-weight: 500; color: #171725;"
                        for="mainCellPhone"
                      >
                        Celular com WhatsApp:
                      </label>
                      <b-button
                        id="popover-mainCellPhone"
                        variant="flat-primary"
                        class="btn-icon rounded-circle p-0 ml-1"
                        style="margin-bottom: 4px;"
                      >
                        <feather-icon
                          icon="InfoIcon"
                          stroke="blue"
                          size="16"
                        />
                      </b-button>
                      <b-popover
                        target="popover-mainCellPhone"
                        variant="primary"
                        triggers="click blur"
                      >
                        <template #title>
                          <h6 class="text-white m-0">
                            Contato com paciente
                          </h6>
                        </template>
                        <p>O paciente pode cadastrar seu número de WhatsApp no app Cuco. Nesse caso, o campo estará desabilitado, mas o número ficará disponível para contato.</p>
                      </b-popover>
                    </div>
                    <validation-provider
                      #default="{ errors }"
                      name="Celular com Whatsapp"
                      :custom-messages="customMessages"
                      :rules="{required: true, regex: /^\d{2}[789]\d{8}$/}"
                    >
                      <b-input-group>
                        <cleave
                          id="mainCellPhone"
                          v-model="mainContact.phone"
                          class="form-control"
                          :raw="true"
                          :options="options.cell"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Ex: (99) 99999-9999"
                          :disabled="mainContact.hasOwnProperty('member_id')"
                        />
                        <b-input-group-append
                          v-if="action === 'detail' && isPhoneValid(mainContact.phone)"
                        >
                          <b-button
                            size="sm"
                            variant="success"
                            :href="createUrl(mainContact.phone)"
                            target="_blank"
                          >
                            <b-img
                              :src="require('@/assets/images/icons/Icon-whatsapp-white.svg')"
                              width="18"
                              height="18"
                            />
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                      <small
                        v-if="errors.length > 0"
                        class="text-danger"
                      >{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    label-for="otherCellPhone"
                  >
                    <div class="d-flex align-items-center">
                      <label
                        style="font-size: 1rem; font-weight: 500; color: #171725;"
                        for="otherCellPhone"
                      >
                        Celular com WhatsApp adicional (opcional):
                      </label>
                      <b-button
                        id="popover-otherCellPhone"
                        variant="flat-primary"
                        class="btn-icon rounded-circle p-0 ml-1"
                        style="margin-bottom: 4px;"
                      >
                        <feather-icon
                          icon="InfoIcon"
                          stroke="blue"
                          size="16"
                        />
                      </b-button>
                      <b-popover
                        target="popover-otherCellPhone"
                        variant="primary"
                        triggers="click blur"
                      >
                        <template #title>
                          <h6 class="text-white m-0">
                            Contato com paciente
                          </h6>
                        </template>
                        <p>O paciente pode cadastrar seu número de WhatsApp no app Cuco. Nesse caso, o campo estará desabilitado, mas o número ficará disponível para contato.</p>
                      </b-popover>
                    </div>
                    <validation-provider
                      #default="{ errors }"
                      name="Celular com Whatsapp adicional"
                      :custom-messages="customMessages"
                      :rules="{required: false, regex: /^\d{2}[789]\d{8}$/}"
                    >
                      <b-input-group>
                        <cleave
                          id="otherCellPhone"
                          v-model="otherContact.phone"
                          class="form-control"
                          :raw="true"
                          :options="options.cell"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Ex: (99) 99999-9999"
                          :disabled="otherContact.hasOwnProperty('member_id')"
                        />
                        <b-input-group-append
                          v-if="action === 'detail' && isPhoneValid(otherContact.phone)"
                        >
                          <b-button
                            size="sm"
                            variant="success"
                            target="_blank"
                            :href="createUrl(otherContact.phone)"
                          >
                            <b-img
                              :src="require('@/assets/images/icons/Icon-whatsapp-white.svg')"
                              width="18"
                              height="18"
                            />
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                      <small
                        v-if="errors.length > 0"
                        class="text-danger"
                      >{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="4">
                  <b-form-group
                    label-for="name"
                    label="Estado:"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Estado"
                      rules="required"
                    >
                      <v-select
                        v-model="uf"
                        class="style-select"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        :options="ufOption"
                        :reduce="optionsUf => optionsUf.code"
                        placeholder="Selecione"
                        @search:blur="citySearch"
                      >
                        <div slot="no-options">
                          Nenhum registro encontrado.
                        </div>
                      </v-select>
                      <small
                        v-if="errors.length > 0"
                        class="text-danger"
                      >{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    label-for="name"
                    label="Cidade:"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Cidade"
                      rules="required"
                    >
                      <v-select
                        v-model="city"
                        class="style-select"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        :options="cityOption"
                        :disabled="cityDisabled"
                        placeholder="Selecione"
                        :reduce="optionsCity => optionsCity.id"
                      >
                        <div slot="no-options">
                          Nenhum registro encontrado.
                        </div>
                      </v-select>
                      <small
                        v-if="errors.length > 0"
                        class="text-danger"
                      >{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

              </b-row>
            </div>

            <div class="form-group">
              <label class="m-0">Tipo de solicitação:</label>
              <b-row>
                <validation-provider
                  #default="{ errors }"
                  name="Tipo de solicitação"
                  rules="required"
                  vid="requestType"
                >
                  <div class="demo-inline-spacing ml-2">
                    <b-form-radio
                      v-model="requestType"
                      name="requestType"
                      value="1"
                      @change="alterResorceRequest('SUS')"
                    >
                      SUS
                    </b-form-radio>
                    <b-form-radio
                      v-model="requestType"
                      name="requestType"
                      value="2"
                      @change="alterResorceRequest('PRIVATE')"
                    >
                      Particular
                    </b-form-radio>
                    <b-form-radio
                      v-model="requestType"
                      name="requestType"
                      value="3"
                      @change="alterResorceRequest('HEALTH_INSURANCE')"
                    >
                      Convênio
                    </b-form-radio>
                  </div>
                  <small
                    v-if="errors.length > 0"
                    class="text-danger ml-2"
                  >{{ errors[0] }}</small>
                </validation-provider>
                <div class="col">
                  <validation-provider
                    #default="{ errors }"
                    name="Convênio"
                    rules="required_if:requestType,3"
                  >
                    <v-select
                      v-model="insurance"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="insuranceOption"
                      placeholder="Digite o convênio"
                      class="mt-1"
                      :disabled="!(requestType === '3')"
                      @search="insuranceData"
                    >
                      <div slot="no-options">
                        Nenhum registro encontrado.
                      </div>
                    </v-select>
                    <small
                      v-if="errors.length > 0"
                      class="text-danger"
                    >{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-row>
            </div>
            <div>
              <b-card-title class="text-gray-800 mt-3">
                Dados da primeira consulta:
              </b-card-title>
              <div>
                <b-row>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      label="Data:"
                      label-for="date"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Data"
                        rules="required"
                      >
                        <b-input-group class="input-group-merge">
                          <flat-pickr
                            id="date"
                            v-model="date"
                            class="form-control"
                            :config="configDate"
                            placeholder="Selecione"
                          />
                          <b-input-group-append>
                            <b-input-group-text>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-calendar4"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v1h14V3a1 1 0 0 0-1-1H2zm13 3H1v9a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V5z" />
                              </svg>
                            </b-input-group-text>
                          </b-input-group-append>
                        </b-input-group>
                        <small
                          v-if="errors.length > 0"
                          class="text-danger"
                        >{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="8"
                  >
                    <b-form-group
                      label-for="local"
                      label="Local:"
                    >
                      <validation-provider
                        ref="clinicInput"
                        #default="{ errors }"
                        name="Local"
                        rules="requiredClinic"
                      >
                        <div class="d-flex">
                          <v-select
                            v-model="clinicSelected"
                            class="w-100 style-select"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="name"
                            :options="clinicOptions"
                            :reduce="clinicOption => clinicOption.name"
                            :no-drop="clinicOptions.length === 0"
                            placeholder="Ex: Clínica Bari Mais Florianópolis Dr. José da Silva Amorim"
                            @search="searchClinic"
                            @option:selected="changeInput"
                          >
                            <template #search="{ attributes, events }">
                              <input
                                v-model="clinicName"
                                maxlength="60"
                                class="vs__search"
                                v-bind="attributes"
                                v-on="events"
                              >
                              <span style="margin-top: 6px;">{{ clinicLength }}/60</span>
                            </template>
                            <template #open-indicator="{ attributes }">
                              <span v-bind="attributes" />
                            </template>
                            <div slot="no-options" />
                          </v-select>
                        </div>
                        <small
                          v-if="errors.length > 0"
                          class="text-danger"
                        >{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    cols="12"
                    md="2"
                  >
                    <b-form-group
                      label="Peso atual:"
                      label-for="currentWeight"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="peso atual"
                        rules="required"
                      >
                        <b-input-group class="input-group-merge">
                          <cleave
                            id="weight"
                            v-model="weight"
                            class="form-control"
                            :raw="false"
                            :options="options.weight"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Ex: 120"
                          />
                          <b-input-group-append>
                            <b-input-group-text>
                              Kg
                            </b-input-group-text>
                          </b-input-group-append>
                        </b-input-group>
                        <small
                          v-if="errors.length > 0"
                          class="text-danger"
                        >{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="2"
                  >
                    <b-form-group
                      label="IMC:"
                      label-for="imc"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="IMC"
                        rules="required"
                      >
                        <cleave
                          id="bmi"
                          v-model="bmi"
                          cru="false"
                          class="form-control"
                          :raw="false"
                          :options="options.bmi"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Ex: 30"
                        />
                        <small
                          v-if="errors.length > 0"
                          class="text-danger"
                        >{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="8"
                  >
                    <b-form-group
                      label-for="name"
                      label="Indicação:"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Indicação"
                        rules="required"
                      >
                        <v-select
                          v-model="referral"
                          class="style-select"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="name"
                          :options="referralOption"
                          :reduce="referralOption => referralOption.id"
                          placeholder="Selecione"
                        >
                          <div slot="no-options">
                            Nenhum registro encontrado.
                          </div>
                        </v-select>
                        <small
                          v-if="errors.length > 0"
                          class="text-danger"
                        >{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </div>
            <div>
              <b-card-title class="text-gray-800 mt-3">
                Condições de saúde do paciente (opcional):
                <b-button
                  id="popover-cares"
                  variant="flat-primary"
                  class="btn-icon rounded-circle p-0 ml-1"
                  style="margin-bottom: 4px;"
                >
                  <feather-icon
                    icon="InfoIcon"
                    stroke="blue"
                    size="16"
                  />
                </b-button>
                <b-popover
                  target="popover-cares"
                  variant="primary"
                  triggers="click blur"
                >
                  <template #title>
                    <h6 class="text-white m-0">
                      Condições de saúde
                    </h6>
                  </template>
                  <p>O paciente pode indicar suas condições de saúde ou cormobidades no app Cuido. <br> Você pode editar esta lista sempre que necessário.</p>
                </b-popover>
              </b-card-title>
              <div>
                <b-row>
                  <b-col cols="12">
                    <v-select
                      v-model="cares"
                      multiple
                      class="style-select"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="careOptions"
                      placeholder="Adicionar condição de saúde"
                      @search="searchCaresWithDelay"
                    >
                      <div slot="no-options">
                        {{ careLoading ? 'Carregando...' : 'Nenhum resultado encontrado.' }}
                      </div>
                    </v-select>
                  </b-col>
                </b-row>
              </div>
            </div>
            <div v-if="action === 'register'">
              <b-card-title class="text-gray-800 mt-2 mb-1">
                Cadastre o protocolo pré-bariátrica do paciente:
              </b-card-title>
              <p class="text-gray-800 mb-2">
                Se as opções abaixo já estiverem selecionadas significa que o paciente iniciou seu cadastro pelo aplicativo CUCO. Caso necessário, desmarque ou acrescente novos itens para editar.
              </p>
              <validation-provider
                #default="{ errors }"
                name="Cadastro de protocolo pré-bariátrica do paciente"
                rules="required"
              >
                <div>
                  <b-form-radio
                    v-model="protocolType"
                    name="protocolType"
                    value="modelProtocol"
                    class="mt-2"
                    @change="clearProtocol"
                  >
                    Usar protocolo modelo
                  </b-form-radio>
                  <template v-if="protocolType === 'modelProtocol'">
                    <div class="mt-2 col-6">
                      <b-form-group
                        label-for="name"
                        label="Escolha um protocolo modelo:"
                      >
                        <validation-provider
                          #default="{
                            // eslint-disable-next-line vue/no-template-shadow
                            errors
                          }"
                          name="escolha um protocolo modelo"
                          rules="required"
                        >
                          <v-select
                            v-model="protocol"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="name"
                            :options="protocolOption"
                            @search:blur="show(protocol.id)"
                          >
                            <div slot="no-options">
                              Nenhum registro encontrado.
                            </div>
                          </v-select>
                          <small
                            v-if="errors.length > 0"
                            class="text-danger"
                          >{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                    <div
                      v-if="showProtocol && protocol !== ''"
                      class="ml-2 mb-2 "
                    >
                      <protocol-form />
                    </div>
                  </template>
                  <b-form-radio
                    v-model="protocolType"
                    name="protocolType"
                    value="newProtocol"
                    class="mt-2"
                    @change="clearProtocol"
                  >
                    Criar novo protocolo de exames e especialistas
                  </b-form-radio>
                  <template v-if="protocolType === 'newProtocol'">
                    <div
                      v-if="showProtocol"
                      class="m-2"
                    >
                      <protocol-form />
                    </div>
                  </template>
                </div>
                <br>
                <small
                  v-if="errors.length > 0"
                  class="text-danger"
                >{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </b-form>
        </validation-observer>

        <b-row>
          <b-col
            class="mt-2"
            cols="12"
          >
            <b-button
              type="button"
              variant="outline-primary"
              title="Cancelar"
              @click="cancel"
            >
              Cancelar
            </b-button>
            <b-button
              type="button"
              variant="primary"
              class="ml-2"
              :disabled="buttonDisable"
              title="Salvar"
              @click="save"
            >
              Salvar
            </b-button>
          </b-col>
        </b-row>

        <div v-if="action === 'detail'">
          <b-card-header class="mt-4 p-0">
            <b-card-title class="text-gray-800 mb-1">
              Arquivar ou ativar paciente
            </b-card-title>
            <p class="text-gray-800">
              Você pode arquivar este paciente se em algum momento a sua jornada pré-bariátrica foi interrompida. Ele passa a ser identificado como arquivado em seu status na lista de pacientes e deixa de ser contabilizado nos indicadores. Após arquivar um paciente, você pode ativá-lo novamente sempre que necessário.
            </p>
          </b-card-header>

          <b-button
            v-if="bariatricData.bariatric_journey_status !== 'ARCHIVED'"
            type="button"
            variant="outline-danger"
            title="Arquivar paciente"
            :disabled="disabledButton()"
            @click="patientStatus"
          >
            Arquivar paciente
          </b-button>
          <b-button
            v-else
            type="button"
            variant="outline-success"
            title="Ativar paciente"
            :disabled="disabledButton()"
            @click="patientStatus"
          >
            Ativar paciente
          </b-button>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BForm, BRow, BCol, BFormGroup, BButton, BFormRadio,
  BInputGroupAppend, BInputGroup, BInputGroupText, BImg, BPopover,
} from 'bootstrap-vue'
import {
  ValidationProvider, ValidationObserver, localize, extend,
} from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import moment from 'moment'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Portuguese } from 'flatpickr/dist/l10n/pt'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ProtocolForm from './protocol-model/ProtocolModel-form.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BFormRadio,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupText,
    BImg,
    BPopover,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    vSelect,
    Cleave,
    flatPickr,

    ProtocolForm,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      showForm: false,
      action: this.$store.getters['protocolModel/action'],
      bariatricData: this.$store.getters['bariatric/bariatricMember'],
      protocolForm: this.$store.getters['protocolModel/protocolModel'],
      otherContact: {
        phone: null,
      },
      mainContact: {
        phone: null,
      },
      uf: '',
      ufOption: [],
      city: '',
      cityOption: [],
      cityDisabled: true,
      insurance: '',
      insuranceOption: [],
      referral: '',
      referralOption: [],
      protocol: '',
      protocolOption: [],
      protocolModel: '',
      showProtocol: false,
      requestType: '',
      protocolType: '',
      date: '',
      weight: '',
      bmi: '',
      options: {
        cell: {
          numericOnly: true,
          blocks: [0, 2, 5, 4],
          delimiters: ['(', ') ', '-'],
        },
        weight: {
          numeral: true,
          numeralDecimalMark: ',',
          numeralIntegerScale: 3,
          numeralDecimalScale: 1,
        },
        bmi: {
          numeral: true,
          numeralDecimalMark: ',',
          numeralIntegerScale: 2,
          numeralDecimalScale: 1,
        },
      },
      buttonDisable: false,

      configDate: {
        mode: 'single',
        dateFormat: 'd/m/Y',
        locale: Portuguese,
      },
      customMessages: {
        regex: 'Preencha um número de telefone válido.',
      },
      configDateBirthdate: {
        mode: 'single',
        dateFormat: 'd/m/Y',
        locale: Portuguese,
        maxDate: moment().format('DD/MM/YYYY'),
        allowInput: true,
      },
      birthdate: '',
      gender: '',
      genderOptions: [
        {
          code: 'M',
          label: 'Masculino',
        },
        {
          code: 'F',
          label: 'Feminino',
        },
      ],
      careLoading: false,
      careOptions: [],
      cares: [],
      memberId: '',
      clinicOptions: [],
      clinicSelected: '',
      clinicName: '',
      timer: '',
      clinicLength: 0,
    }
  },

  mounted() {
    this.ufData()
    this.protocolData()
    this.referralData()
    this.loadData()
    localize(this.$store.getters['cuido/locale'])

    extend('requiredClinic', {
      ...required,
      validate: () => {
        if (this.clinicName.length === 0) {
          return 'O campo Local é obrigatório'
        }

        if (this.clinicName.length < 3) {
          return 'O campo Local deve ter pelo menos três caracteres.'
        }
        return true
      },
    })
  },

  methods: {
    ufData() {
      this.$store.dispatch('bariatric/ufList')
        .then(response => {
          this.ufOption = response.data.data.states_info
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    async citySearch() {
      this.cityDisabled = true
      this.city = ''
      this.cityOption = []
      const state = this.uf
      if (state !== undefined && state !== null && state !== '') {
        await this.$store.dispatch('bariatric/citySearch', state)
          .then(response => {
            this.cityOption = response.data.data
            this.cityDisabled = false
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ops... Algo deu errado, tente novamente',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
    referralData() {
      this.$store.dispatch('bariatric/referralList')
        .then(response => {
          this.referralOption = response.data.data.referral_sources_info
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    async loadData() {
      const bariatricJourneyId = await this.$store.getters['bariatric/bariatricJourneyId']
      this.$store.dispatch('bariatric/showBariatric', bariatricJourneyId)
        .then(async response => {
          const bariatricData = await response.data.data.bariatric_journey_info
          this.memberId = bariatricData.member_id
          await this.$store.commit('bariatric/UPDATE_BARIATRIC', bariatricData)

          if (this.action === 'register' && (bariatricData.health_specialists_info.length > 0 || bariatricData.exams_info.length > 0)) {
            const selectedExamsOrigin = bariatricData.exams_info.map(a => ({ id: a.id, name: a.name }))
            const selectedHealthSpecialistOrigin = bariatricData.health_specialists_info.map(a => ({ id: a.id, name: a.name }))
            this.$store.commit('protocolModel/RESET')
            this.$store.commit('protocolModel/UPDATE_ACTION', 'bariatricRegister')
            this.$store.commit('protocolModel/UPDATE_PROTOCOL_MODEL_SPECIALIST_IDS', selectedHealthSpecialistOrigin)
            this.$store.commit('protocolModel/UPDATE_PROTOCOL_MODEL_EXAMS_IDS', selectedExamsOrigin)
            this.showProtocol = true
            this.protocolType = 'newProtocol'
          }
          await this.listCares(bariatricData.member_id)
          await this.searchCares()
          this.loadFormData(bariatricData)
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    searchCaresWithDelay(search) {
      this.careLoading = true
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.searchCares(search)
      }, 500)
    },

    async searchCares(search) {
      this.$store.dispatch('bariatric/searchCares', search)
        .then(res => {
          const { member_cares_info } = res.data.data
          // eslint-disable-next-line camelcase
          this.careOptions = member_cares_info
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.careLoading = false
        })
    },

    async listCares(memberId) {
      this.$store.dispatch('bariatric/listCares', memberId)
        .then(res => {
          const { member_cares_info } = res.data.data

          this.cares = member_cares_info.map(item => ({ id: item.care_id, name: item.name }))
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    async loadFormData(bariatricData) {
      this.showform = false

      switch (bariatricData.source_request) {
        case 'SUS':
          this.requestType = '1'
          break
        case 'PRIVATE':
          this.requestType = '2'
          break
        case 'HEALTH_INSURANCE':
          this.requestType = '3'
          break
        default:
          break
      }

      this.insuranceOption = [{ id: bariatricData.health_insurance_plan_info.id, name: bariatricData.health_insurance_plan_info.name }]
      this.insurance = bariatricData.health_insurance_plan_info

      this.date = bariatricData.first_appointment_date ? moment(bariatricData.first_appointment_date, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''

      this.mainContact = bariatricData.contacts_info.find(e => e.type === 'MAIN') ?? this.mainContact
      this.mainContact.phone = this.mainContact.phone ? this.mainContact.phone.slice(2) : ''

      this.otherContact = bariatricData.contacts_info.find(e => e.type === 'OTHER') ?? this.otherContact
      this.otherContact.phone = this.otherContact.phone ? this.otherContact.phone.slice(2) : ''

      this.clinicName = bariatricData.clinic_name ?? ''
      this.clinicLength = this.clinicName.length
      this.weight = bariatricData.current_weight?.toString().replace('.', ',')
      this.referral = bariatricData.referral_source_id ?? null

      if (this.action === 'detail') {
        this.uf = bariatricData.state_id
        await this.citySearch()
        this.city = bariatricData.city_id
        this.bmi = bariatricData.current_bmi?.toString().replace('.', ',')
      }
      this.showForm = true

      this.birthdate = bariatricData.birthdate ? moment(bariatricData.birthdate).format('DD/MM/YYYY') : ''

      this.gender = bariatricData.gender ? bariatricData.gender : ''
    },

    insuranceData(search, loading) {
      clearTimeout(this.timer)
      if (search.length >= 3) {
        this.startTime(search, loading)
        loading(true)
      } else {
        this.option = []
      }
    },
    startTime(search, loading) {
      this.timer = setTimeout(() => {
        this.find(search, loading)
      }, 500)
    },
    find(search, loading) {
      this.$store.dispatch('bariatric/insuranceSearch', search)
        .then(response => {
          this.insuranceOption = response.data.data.health_insurance_plans_info
          loading(false)
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    patientStatus() {
      if (this.bariatricData.bariatric_journey_status !== 'ARCHIVED') {
        this.$swal({
          title: '',
          html: '<p><strong>Deseja arquivar este paciente?</strong><br><br>Após arquivar, os dados deste paciente não contabilizarão mais nos indicadores. Você poderá desarquivar novamente ao acessar o perfil do paciente.</p>',
          icon: 'warning',
          iconColor: '#D45858',
          showCancelButton: true,
          confirmButtonText: 'Arquivar',
          cancelButtonText: 'Cancelar',
          customClass: {
            popup: 'p-2',
            actions: 'pb-1',
            confirmButton: 'btn btn-danger mx-1',
            cancelButton: 'btn btn-flat-primary mx-1',
          },
          reverseButtons: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          buttonsStyling: false,
          preConfirm: () => this.saveStatus('ARCHIVED'),
        })
      } else {
        this.saveStatus('ACTIVE')
      }
    },
    saveStatus(status) {
      const dataForm = {
        bariatric_journey_id: this.$store.getters['bariatric/bariatricId'],
        bariatric_journey_info: {
          status,
        },
      }
      this.$store.dispatch('bariatric/saveBariatricStatus', dataForm)
        .then(() => {
          this.$store.commit('bariatric/UPDATE_BARIATRIC_STATUS', status)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `${status === 'ARCHIVED' ? 'Paciente arquivado com sucesso!' : 'Paciente desarquivado com sucesso!'}`,
              text: `${status === 'ARCHIVED' ? 'Você poderá desarquivar novamente ao acessar o perfil do paciente.' : 'Você poderá arquivar novamente ao acessar o perfil do paciente.'}`,
              size: 'sm',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          this.$router.push('/bariatric').catch(() => {})
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    isPhoneValid(phone) {
      const cell = phone
      if (cell.length === 11 && (cell.substring(2, 3) === '7' || cell.substring(2, 3) === '8' || cell.substring(2, 3) === '9')) {
        return true
      }
      return false
    },
    createUrl(phone) {
      return `https://wa.me/55${phone}`
    },

    async show(id) {
      this.showProtocol = false
      await this.$store.dispatch('protocolModel/show', id)
        .then(response => {
          const protocolModelInfo = response.data.data.bariatric_protocol_model_info
          if (protocolModelInfo !== null) {
            this.$store.commit('protocolModel/RESET')
            this.$store.commit('protocolModel/UPDATE_ACTION', 'bariatricRegister')
            this.$store.commit('protocolModel/UPDATE_ID_SELECT', protocolModelInfo.id)
            this.$store.commit('protocolModel/UPDATE_PROTOCOL_MODEL_NAME', protocolModelInfo.name)
            this.$store.commit('protocolModel/UPDATE_PROTOCOL_MODEL_SPECIALIST_IDS', protocolModelInfo.health_specialists)
            this.$store.commit('protocolModel/UPDATE_PROTOCOL_MODEL_EXAMS_IDS', protocolModelInfo.exams)
          }
          this.showProtocol = true
        })
    },

    protocolData() {
      this.$store.dispatch('protocolModel/listProtocols')
        .then(response => {
          this.protocolOption = response.data.data.bariatric_protocol_models_info
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    async clearProtocol() {
      this.showProtocol = false
      this.protocol = ''
      await this.$store.commit('protocolModel/RESET')
      await this.$store.commit('protocolModel/UPDATE_ACTION', 'bariatricRegister')
      this.showProtocol = true
    },
    alterResorceRequest(val) {
      if (val === 'SUS' || val === 'PRIVATE') {
        this.insurance = ''
      }
    },
    disabledButton() {
      if (this.bariatricData.bariatric_journey_status === 'SURGERY_DONE' || this.bariatricData.journey_progress.surgery === true) {
        return true
      }
      return false
    },
    formatPhone(phone) {
      if (!phone) {
        return null
      }
      return `55${phone}`
    },

    save() {
      this.$refs.bariatricRules.validate().then(success => {
        if (success) {
          this.buttonDisable = true
          let dataForm = {}
          if (this.action === 'register') {
            dataForm = {
              bariatric_journey_id: this.bariatricData.bariatric_journey_id,
              bariatric_journey_info: {
                id: this.bariatricData.bariatric_journey_id,
                city_id: this.city,
                source_request_id: this.requestType,
                health_insurance_plan_id: this.insurance?.id ?? null,
                referral_source_id: this.referral,
                current_weight: this.weight.replace(',', '.'),
                current_bmi: this.bmi.replace(',', '.'),
                first_appointment_date: moment(this.date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                clinic_name: this.clinicName,
                contact_phone: this.mainContact.member_id ? null : `55${this.mainContact.phone}`,
                extra_phone: this.otherContact.member_id ? null : this.formatPhone(this.otherContact.phone),
                exam_ids: this.$store.getters['protocolModel/selectedExamIds'].map(e => e.id),
                health_specialist_ids: this.$store.getters['protocolModel/selectedSpecialistIds'].map(e => e.id),
                member_id: this.bariatricData.member_id,
                birthdate: moment(this.birthdate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                gender: this.gender,
              },
            }
          } else {
            dataForm = {
              bariatric_journey_id: this.bariatricData.bariatric_journey_id,
              bariatric_journey_info: {
                id: this.bariatricData.bariatric_journey_id,
                city_id: this.city,
                source_request_id: this.requestType,
                health_insurance_plan_id: this.insurance?.id ?? null,
                referral_source_id: this.referral,
                current_weight: this.weight.replace(',', '.'),
                current_bmi: this.bmi.replace(',', '.'),
                first_appointment_date: moment(this.date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                clinic_name: this.clinicName,
                contact_phone: this.mainContact.member_id ? null : `55${this.mainContact.phone}`,
                extra_phone: this.otherContact.member_id ? null : this.formatPhone(this.otherContact.phone),
                member_id: this.bariatricData.member_id,
                birthdate: moment(this.birthdate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                gender: this.gender,
              },
            }
          }

          this.$store.dispatch('bariatric/saveBariatric', dataForm)
            .then(() => {
              const payload = {
                cares: this.cares.map(item => item.id),
                memberId: this.memberId,
              }
              this.$store.dispatch('bariatric/updateCares', payload)
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `${this.action === 'register' ? 'Paciente cadastrado com sucesso!' : 'Dados do paciente salvos com sucesso!'}`,
                      text: `${this.action === 'register' ? 'Você já pode acompanhar este paciente no menu “Jornada bariátrica”.' : 'Você pode editar os dados sempre que quiser acessando os “Detalhes” dos pacientes.'}`,
                      size: 'sm',
                      icon: 'CheckCircleIcon',
                      variant: 'success',
                    },
                  })
                })
                .catch(() => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Erro ao salvar o paciente',
                      text: 'Ops... Algo deu errado, tente novamente',
                      size: 'sm',
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  })
                })

              let requestType = ''
              switch (this.requestType) {
                case '1':
                  requestType = 'SUS'
                  break
                case '2':
                  requestType = 'PRIVATE'
                  break
                case '3':
                  requestType = 'HEALTH_INSURANCE'
                  break
                default:
                  break
              }
              this.$store.commit('bariatric/UPDATE_SOURCE_REQUEST', requestType)
              this.$store.commit('survey/UPDATE_BARIATRIC_SURVEY', true)
              this.$router.push('/bariatric-detail').catch(() => {})
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Erro ao salvar o paciente',
                  text: 'Ops... Algo deu errado, tente novamente',
                  size: 'sm',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
              this.$router.push('/bariatric-detail').catch(() => {})
            })
            .finally(() => {
              this.buttonDisable = false
            })
        } else if (!success && (this.action === 'register' || this.action === 'detail')) {
          const invalidField = this.$el.querySelector('.text-danger:first-of-type')
          if (invalidField) {
            invalidField.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            })
          }
        }
      })
    },
    cancel() {
      this.$router.push('bariatric')
    },
    searchClinic(name) {
      this.clinicOptions = []
      if (name !== this.clinicName) {
        return
      }
      this.clinicLength = name.length
      if (name.length >= 3) {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.$store.dispatch('bariatric/searchClinics', name)
            .then(response => {
              const { bariatric_clinics_info } = response.data.data
              // eslint-disable-next-line camelcase
              this.clinicOptions = bariatric_clinics_info.filter(clinic => clinic.name !== 'DEFAULT')
            })
        }, 500)
      }
      this.$refs.clinicInput.validate()
    },
    changeInput(value) {
      this.clinicName = value.name
      this.clinicSelected = ''
      this.clinicLength = value.name.length
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
.style-select::v-deep .vs__search::placeholder {
  color: #b8c2cc;
}
</style>
