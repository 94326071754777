<template>
  <validation-observer ref="protocolModelForm">
    <b-form @submit.prevent>
      <b-row>
        <b-col lg="6">
          <div
            v-if="action === 'register'"
          >
            <b-form-group
              label-for="name"
              label="Nome do protocolo modelo:"
              label-class="text-gray-800"
              class="mb-3"
            >
              <validation-provider
                #default="{ errors }"
                name="nome do protocolo modelo"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="form.name"
                  name="register-number"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Digite o nome"
                />
                <small
                  v-if="errors.length > 0"
                  class="text-danger"
                >{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>

          <div>
            <protocol-specialist />
          </div>
          <div class="mt-2">
            <protocol-exams />
          </div>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  ValidationProvider, ValidationObserver, localize,
} from 'vee-validate'
import {
  BForm, BFormGroup, BFormInput, BCol, BRow,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import protocolSpecialist from './ProtocolModel-form-specialist.vue'
import protocolExams from './ProtocolModel-form-exams.vue'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,

    ValidationProvider,
    ValidationObserver,

    protocolSpecialist,
    protocolExams,
  },

  data() {
    return {
      action: this.$store.getters['protocolModel/action'],
      form: {
        id: this.$store.getters['protocolModel/idSelect'],
        name: this.$store.getters['protocolModel/protocolModelName'],
      },
    }
  },

  watch: {
    form: {
      handler(newVal) {
        this.$store.commit('protocolModel/UPDATE_PROTOCOL_MODEL_NAME', newVal)
      },
      deep: true,
    },
  },

  async mounted() {
    localize(this.$store.getters['cuido/locale'])
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest';
</style>
