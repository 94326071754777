<!-- eslint-disable vue/no-v-html -->
<template>
  <div v-if="showForm">
    <validation-observer ref="examsRules">
      <b-form>
        <h5 class="text-gray-800">
          Realização de exames:
        </h5>
        <b-row class="mt-2">
          <div
            class="col-auto form-group"
            style="margin-top: 10px"
          >
            <label for="specialist-status">Status:</label>
          </div>
          <div
            class="col-3"
          >
            <validation-provider
              #default="{ errors }"
              name="Indicação"
              rules="required"
            >
              <v-select
                v-model="status"
                :options="statusOption"
                :reduce="option => option.id"
                :clearable="false"
              >
                <template v-slot:option="option">
                  <div
                    style="padding: 2px 0;"
                    v-html="option.label"
                  />
                </template>
                <template v-slot:selected-option="option">
                  <div
                    v-html="option.label"
                  />
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </div>
        </b-row>
        <div class="mt-2">
          <h5 class="text-gray-800 mb-1">
            Lista dos exames incluídos no protocolo do seu paciente:
          </h5>

          <validation-provider
            #default="{ errors }"
            rules="requiredExams"
          >
            <transition-group
              name="fade"
              :duration="150"
              mode="out-in"
            >
              <div
                v-for="option in selectedExams"
                :key="option.id"
                :value="option"
                name="exams"
              >
                <b-row
                  class="align-items-center"
                  style="margin-bottom: 5px"
                >
                  <div class="col">
                    <div style="margin-top: 10px">
                      {{ option.name }}
                    </div>
                  </div>
                  <div class="col-8 p-0">
                    <feather-icon
                      size="18"
                      icon="Trash2Icon"
                      color="#ea5455"
                      style="cursor: pointer"
                      @click="alterOption(option)"
                    />
                  </div>
                </b-row>
              </div>
            </transition-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </div>
        <div class="mt-3">
          <h5 class="text-gray-800 mb-1">
            Selecione os exames que deseja indicar ao seu paciente:
          </h5>
          <protocol-exams />
        </div>
        <b-row>
          <b-col
            cols="6"
            class="mt-3"
          >
            <b-button
              type="button"
              variant="outline-primary"
              title="Cancelar"
              @click="cancel"
            >
              Cancelar
            </b-button>
            <b-button
              type="button"
              variant="primary"
              class="ml-2"
              :disabled="buttonDisable"
              title="Salvar"
              @click="save"
            >
              Salvar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import {
  BRow, BCol, BButton, BForm,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'
import { required } from 'vee-validate/dist/rules'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import protocolExams from './protocol-model/ProtocolModel-form-exams.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BForm,

    vSelect,

    ValidationProvider,
    ValidationObserver,

    protocolExams,
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      showForm: false,
      status: 'PENDING',
      statusOption: [
        {
          id: 'PENDING',
          label: '<span class="badge badge-bariatric badge-warning">Em aberto</span>',
        },
        {
          id: 'DONE',
          label: '<span class="badge badge-bariatric badge-success">Concluído</span>',
        },
      ],
      selectedExamsOrigin: '',
      statusOrigin: '',
      buttonDisable: false,
    }
  },

  computed: {
    ...mapGetters({
      selectedExams: 'protocolModel/selectedExamIds',
    }),
  },

  watch: {
    // eslint-disable-next-line func-names
    selectedExams() {
      const healthExamsSort = this.selectedExams.slice().sort((a, b) => a.id - b.id)

      if ((JSON.stringify(this.selectedExamsOrigin) !== JSON.stringify(healthExamsSort)
      ) || this.statusOrigin === 'PENDING') {
        this.status = 'PENDING'
      } else {
        this.status = 'DONE'
      }
    },
  },

  created() {
    this.loadData()
  },

  mounted() {
    extend('requiredExams', {
      ...required,
      validate: () => {
        if (this.selectedExams.length !== 0) {
          return true
        }
        return ''
      },
    })
  },

  methods: {
    alterOption(value) {
      this.$store.commit('protocolModel/UPDATE_SELECT_EXAM_REMOVE', value)
      this.$store.commit('protocolModel/UPDATE_OPTION_EXAM_PUSH', value)
    },
    async loadData() {
      this.showForm = false
      const bariatricData = this.$store.getters['bariatric/bariatricMember']
      await this.$store.dispatch('bariatric/showExam', { bariatric_journey_id: bariatricData.bariatric_journey_id })
        .then(response => {
          const { data } = response.data
          if (data.status === true) {
            this.statusOrigin = 'DONE'
          } else {
            this.statusOrigin = 'PENDING'
          }
          this.$store.commit('protocolModel/UPDATE_PROTOCOL_MODEL_EXAMS_IDS', data.bariatric_exams_info)
          this.selectedExamsOrigin = data.bariatric_exams_info.slice().sort((a, b) => a.id - b.id)
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
      this.showForm = true
    },
    changeStatusJourney() {
      if (this.status === 'PENDING') {
        this.$store.commit('bariatric/UPDATE_JOURNEY_PROGRESS', { key: 'bariatric_journey_exam', value: false })
      } else {
        this.$store.commit('bariatric/UPDATE_JOURNEY_PROGRESS', { key: 'bariatric_journey_exam', value: true })
      }
    },
    save() {
      this.$refs.examsRules.validate().then(success => {
        if (success) {
          this.buttonDisable = true
          const bariatricData = this.$store.getters['bariatric/bariatricMember']
          const dataForm = {
            bariatric_journey_id: bariatricData.bariatric_journey_id,
            bariatric_exam_info: {
              status: this.status,
              exam_ids: this.selectedExams.map(exam => exam.id),
            },
          }
          this.$store.dispatch('bariatric/saveExam', dataForm)
            .then(() => {
              this.changeStatusJourney()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Dados do paciente salvos com sucesso!',
                  text: 'Você pode editar os dados sempre que quiser acessando os “Detalhes” dos pacientes.',
                  size: 'sm',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
              this.$store.commit('survey/UPDATE_BARIATRIC_SURVEY', true)
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Erro ao salvar os dados do paciente',
                  text: 'Ops... Algo deu errado, tente novamente',
                  size: 'sm',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
            .finally(() => {
              this.buttonDisable = false
            })
        }
      })
    },
    cancel() {
      this.$router.push('/bariatric')
    },
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
