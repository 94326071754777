<template>
  <validation-observer ref="protocolModelForm">
    <b-form @submit.prevent>
      <b-row>
        <b-col>
          <div v-if="form.optionsHealthSpecialists.length > 0">
            <b-form-group
              label-class="text-gray-800"
              :label="textLabel"
            >
              <div v-if="form.optionsHealthSpecialists.length > 0">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <transition-group
                    name="fade"
                    :duration="150"
                    mode="out-in"
                  >
                    <b-form-checkbox
                      v-for="option in form.optionsHealthSpecialists"
                      :key="option.id"
                      v-model="form.selectedsHealthSpecialist"
                      :value="option"
                      name="healthSpecialists"
                      class="mb-1"
                      @change="removeOption(option)"
                    >
                      {{ option.name }}
                    </b-form-checkbox>
                  </transition-group>

                  <template>
                    <b-row>
                      <b-col>
                        <vue-autosuggest
                          v-model="form.currentHealthSpecialistSuggestValue"
                          :suggestions="form.optionsHealthSpecialistSuggest"
                          :limit="10"
                          :input-props="{id:'autosuggest__input', class:'form-control', placeholder:'Adicionar outro especialista'}"
                          @selected="onHealthSpecialistSelected"
                          @input="onHealthSpecialistInputChange"
                        >
                          <template slot-scope="{suggestion}">
                            <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
                          </template>
                        </vue-autosuggest>
                      </b-col>
                      <b-col md="3">
                        <transition-group
                          name="fade"
                          :duration="150"
                        >
                          <b-button
                            v-show="form.emptyHealthSpecialistSuggest && form.currentHealthSpecialistSuggestValue.length > 0"
                            key="add-button"
                            variant="primary"
                            class="btn-icon mr-md-1"
                            @click="addHealthSpecialist"
                          >
                            <feather-icon
                              icon="CheckIcon"
                              size="18"
                            />
                          </b-button>
                          <b-button
                            v-show="form.emptyHealthSpecialistSuggest && form.currentHealthSpecialistSuggestValue.length > 0"
                            key="clear-button"
                            variant="danger"
                            class="btn-icon"
                            @click="clearSuggest"
                          >
                            <feather-icon
                              icon="XIcon"
                              size="18"
                            />
                          </b-button>
                        </transition-group>
                      </b-col>
                    </b-row>
                  </template>

                  <small
                    v-if="errors.length > 0"
                    class="text-danger"
                  >
                    Selecione pelo menos um especialista para incluir no protocolo.
                  </small>
                </validation-provider>
              </div>
              <template v-else>
                <small class="text-muted">Nenhum especialista encontrado.</small>
              </template>
            </b-form-group>

          </div>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  ValidationProvider, ValidationObserver, localize,
} from 'vee-validate'
import { VueAutosuggest } from 'vue-autosuggest'
import {
  BForm, BFormGroup, BButton, BCol, BRow, BFormCheckbox,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BForm,
    BFormGroup,
    BButton,
    BRow,
    BCol,
    BFormCheckbox,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    VueAutosuggest,

    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      action: this.$store.getters['protocolModel/action'],
      idSelected: this.$store.getters['protocolModel/idSelect'],
      textLabel: '',
      form: {
        optionsHealthSpecialists: this.$store.getters['protocolModel/protocolModelSpecialistIds'],

        selectedsHealthSpecialist: [],

        optionsHealthSpecialistSuggest: [],
        emptyHealthSpecialistSuggest: false,
        currentHealthSpecialistSuggestValue: '',

      },
    }
  },

  watch: {
    form: {
      handler(newVal) {
        this.$store.commit('protocolModel/UPDATE_PROTOCOL_MODEL_SPECIALIST', newVal)
      },
      deep: true,
    },
  },

  async mounted() {
    localize(this.$store.getters['cuido/locale'])
    this.changeLabel()
    await this.listHealthSpecialists()
  },

  methods: {
    changeLabel() {
      if (this.action === 'detail') {
        this.textLabel = ''
      } else {
        this.textLabel = 'Selecione as avaliações que deseja incluir neste protocolo:'
      }
    },
    async listHealthSpecialists() {
      await this.$store.dispatch('protocolModel/listHealthSpecialists', { main: true })
        .then(response => {
          const healthSpecialistsInfo = response.data.data.health_specialists_info
          if (healthSpecialistsInfo !== null) {
            const options = []
            const selectedArray = this.form.optionsHealthSpecialists.map(e => e.id)
            healthSpecialistsInfo.forEach(item => {
              if (selectedArray.indexOf(item.id) === -1 && this.action === 'detail') {
                options.push({
                  id: item.id,
                  name: item.name,
                })
              } else if (this.action === 'register' || this.action === 'bariatricRegister') {
                options.push({
                  id: item.id,
                  name: item.name,
                })
              }
            })
            const ids = new Set(options.map(d => d.id))
            this.form.selectedsHealthSpecialist = this.form.optionsHealthSpecialists
            if (this.action === 'detail') {
              this.form.optionsHealthSpecialists = [...options]
            } else {
              this.form.optionsHealthSpecialists = [...options, ...this.form.optionsHealthSpecialists.filter(d => !ids.has(d.id))]
            }
          }
        })
    },
    addHealthSpecialist() {
      if (this.form.currentHealthSpecialistSuggestValue.length > 0) {
        const data = { name: this.form.currentHealthSpecialistSuggestValue }
        this.$store.dispatch('protocolModel/addHealthSpecialist', { data })
          .then(response => {
            const healthSpecialistInfo = response.data.data.health_specialist_info
            if (this.action !== 'detail') {
              this.form.optionsHealthSpecialists.push({ id: healthSpecialistInfo.id, name: healthSpecialistInfo.name })
            }
            this.form.selectedsHealthSpecialist.push({ id: healthSpecialistInfo.id, name: healthSpecialistInfo.name })
            this.form.currentHealthSpecialistSuggestValue = ''

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Item cadastrado com sucesso',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          })
          .catch(() => {
            this.form.currentHealthSpecialistSuggestValue = ''
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ops... Algo deu errado, tente novamente',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
    onHealthSpecialistInputChange(search) {
      if (search.length < 2) {
        return
      }
      this.$store.dispatch('protocolModel/listHealthSpecialists', { main: false, name: search })
        .then(response => {
          const healthSpecialistsInfo = response.data.data.health_specialists_info
          if (healthSpecialistsInfo !== null && healthSpecialistsInfo.length > 0) {
            const options = []
            healthSpecialistsInfo.forEach(item => {
              options.push({
                id: item.id,
                name: item.name,
              })
            })
            this.form.optionsHealthSpecialistSuggest = [{
              data: options,
            }]
            this.form.emptyHealthSpecialistSuggest = false
          } else {
            this.form.emptyHealthSpecialistSuggest = true
            this.form.optionsHealthSpecialistSuggest = []
          }
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    onHealthSpecialistSelected(option) {
      if (this.form.optionsHealthSpecialists.find(item => item.id === option.item.id)) {
        const item = {
          id: option.item.id,
          name: option.item.name,
        }
        this.form.selectedsHealthSpecialist.push(item)
      } else {
        const item = {
          id: option.item.id,
          name: option.item.name,
        }
        this.form.selectedsHealthSpecialist.push(item)
        if (this.action !== 'detail') {
          this.form.optionsHealthSpecialists.push(item)
        }
      }
      this.form.currentHealthSpecialistSuggestValue = null
      this.form.optionsHealthSpecialistSuggest = []
    },
    async show(id) {
      await this.$store.dispatch('protocolModel/show', { id })
        .then(response => {
          const protocolModelInfo = response.data.data.bariatric_protocol_model_info
          if (protocolModelInfo !== null) {
            this.protocolModel = {
              id: protocolModelInfo.id,
              name: protocolModelInfo.name,
              healthSpecialists: protocolModelInfo.health_specialists,
              exams: protocolModelInfo.exams,
            }
          }
        })
    },
    removeOption(value) {
      if (this.action === 'detail') {
        const index = this.form.optionsHealthSpecialists.indexOf(value)
        this.form.optionsHealthSpecialists.splice(index, 1)
      }
    },
    clearSuggest() {
      this.form.currentHealthSpecialistSuggestValue = ''
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest';
</style>
