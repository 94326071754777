<template>
  <div>
    <validation-observer ref="ActivityRules">
      <b-form>
        <div>
          <validation-provider
            #default="{ errors }"
            name="observação"
            rules="required"
          >
            <b-form-textarea
              id="textarea-state"
              v-model="comment"
              placeholder="Insira aqui observações sobre a jornada do paciente"
              rows="3"
              trim
              :class="comment.length >= maxChar ? 'text-danger' : ''"
              maxlength="255"
            />
            <small
              class="textarea-counter-value float-right"
              :class="comment.length >= maxChar ? 'bg-danger' : ''"
            >
              <span class="char-count">{{ comment.length }}</span> / {{ maxChar }}
            </small>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </div>

        <div
          class="mt-3 col-12 text-right m-0"
        >
          <b-button
            type="button"
            variant="outline-primary"
            @click="cancel"
          >
            Cancelar
          </b-button>
          <b-button
            type="button"
            variant="primary"
            class="ml-2"
            :disabled="buttonDisable"
            @click="create"
          >
            Salvar
          </b-button>
        </div>

      </b-form>
    </validation-observer>

    <div class="mt-3">
      <h5>
        Observações:
      </h5>
      <div class="mt-2">
        <div
          v-if="comments.length > 0"
        >
          <validation-observer ref="ActivityRulesEdit">
            <div
              v-for="commentList in comments"
              :key="commentList.id"
            >
              <div>
                <b-row v-if="editId !== commentList.id">
                  <div
                    class="col-10 text-justify"
                  >
                    <strong>{{ commentList.description }}</strong>
                  </div>
                  <div class="col text-right">
                    <feather-icon
                      icon="Edit3Icon"
                      size="20"
                      style="cursor: pointer"
                      color="#5A607F"
                      @click="formEdit(commentList)"
                    />
                    <feather-icon
                      icon="TrashIcon"
                      size="20"
                      class="ml-1"
                      style="cursor: pointer"
                      color="#5A607F"
                      @click="showRemoveModal(), commentId=commentList.id"
                    />
                  </div>
                </b-row>
                <b-row v-else>

                  <div
                    class="col-12 text-justify"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="observação"
                      rules="required"
                    >
                      <b-form-textarea
                        id="textarea-state"
                        v-model="commentEdit"
                        placeholder="Insira aqui observações sobre a jornada do paciente"
                        rows="3"
                        trim
                        :class="commentEdit.length >= maxChar ? 'text-danger' : ''"
                        maxlength="255"
                      />
                      <small
                        class="textarea-counter-value float-right"
                        :class="commentEdit.length >= maxChar ? 'bg-danger' : ''"
                      >
                        <span class="char-count">{{ commentEdit.length }}</span> / {{ maxChar }}
                      </small>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                  <div
                    class="mt-3 col-12 text-right m-0"
                  >
                    <b-button
                      type="button"
                      variant="outline-primary"
                      @click="cancelEdit"
                    >
                      Cancelar
                    </b-button>
                    <b-button
                      type="button"
                      variant="primary"
                      class="ml-2"
                      :disabled="buttonDisable"
                      @click="edit"
                    >
                      Salvar
                    </b-button>
                  </div>

                </b-row>
                <div class="mt-1">
                  <small>
                    {{ formatDate(commentList.updated_at, commentList.created_at) }}
                  </small>
                </div>
                <hr>
              </div>
            </div>
          </validation-observer>
        </div>
        <div
          v-else
          class="mb-3 text-center"
        >
          Você ainda não possui observações salvas.
        </div>
      </div>
      <b-row>
        <b-col>
          <span class="text-muted">Mostrando {{ dataMeta.from }} a {{ dataMeta.to }} de {{ dataMeta.of }} entradas</span>
        </b-col>
        <b-col>
          <b-pagination
            v-model="currentPage"
            :per-page="perPage"
            :total-rows="rows"
            first-number
            last-number
            align="center"
            prev-class="prev-item"
            next-class="next-item"
            @input="loadData"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
        <b-col
          cols="12"
          sm="4"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <label>Mostrar</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50 viewItens"
          />
          <label>itens</label>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BFormTextarea, BRow, BPagination, BCol, BForm,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'

export default {
  components: {
    BRow,
    BButton,
    BFormTextarea,
    BPagination,
    BCol,
    BForm,

    ValidationProvider,
    ValidationObserver,

    vSelect,
  },

  data() {
    return {
      commentId: '',
      comment: '',
      commentEdit: '',
      maxChar: 255,
      comments: [],
      currentPage: 1,
      perPage: 10,
      rows: '',
      dataMeta: {
        from: '',
        to: '',
        of: '',
      },
      perPageOptions: [10, 25, 50, 100],
      buttonDisable: false,
      editId: '',
    }
  },

  created() {
    this.loadData()
  },

  methods: {
    async loadData() {
      this.showForm = false
      const bariatricData = this.$store.getters['bariatric/bariatricMember']
      const formData = {
        bariatric_journey_id: bariatricData.bariatric_journey_id,
        page: this.currentPage,
        limit: this.perPage,
      }

      await this.$store.dispatch('bariatric/showComment', formData)
        .then(response => {
          const { data } = response.data
          this.rows = data.total
          this.comments = data.bariatric_journey_comments_info
          this.informationPage()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
      this.showForm = true
    },
    formatDate(valueUpdate, valueCreated) {
      let text = ''
      if (valueCreated < valueUpdate) {
        text = '(editado)'
      }
      return `${moment(valueUpdate).locale('pt').format('DD MMM yyyy [ás] HH:mm')} ${text}`
    },
    informationPage() {
      const localItemsCount = this.comments ? this.comments.length : 0
      this.dataMeta.from = this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0)
      this.dataMeta.to = this.perPage * (this.currentPage - 1) + localItemsCount
      this.dataMeta.of = this.rows
    },
    formEdit(comment) {
      this.commentEdit = comment.description
      this.commentId = comment.id
      this.editId = comment.id
    },
    create() {
      this.$refs.ActivityRules.validate().then(success => {
        if (success) {
          this.buttonDisable = true
          const bariatricData = this.$store.getters['bariatric/bariatricMember']
          const dataForm = {
            bariatric_journey_id: bariatricData.bariatric_journey_id,
            bariatric_journey_comment_info: {
              description: this.comment,
            },
          }
          this.$store.dispatch('bariatric/saveComment', dataForm)
            .then(() => {
              this.comment = ''
              this.$refs.ActivityRules.reset()
              this.loadData()

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Observação salva com sucesso!',
                  text: 'Você pode editar as observações criadas sempre que quiser acessando os “Detalhes” dos pacientes.',
                  size: 'sm',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
              this.$store.commit('survey/UPDATE_BARIATRIC_SURVEY', true)
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Erro ao salvar a observação',
                  text: 'Ops... Algo deu errado, tente novamente',
                  size: 'sm',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
            .finally(() => {
              this.buttonDisable = false
            })
        }
      })
    },
    edit() {
      if (this.commentId) {
        this.$refs.ActivityRulesEdit.validate().then(success => {
          if (success) {
            this.buttonDisable = true
            const dataForm = {
              bariatric_journey_comment_id: this.commentId,
              bariatric_journey_comment_info: {
                description: this.commentEdit,
              },
            }
            this.$store.dispatch('bariatric/updateComment', dataForm)
              .then(() => {
                this.loadData()
                this.commentEdit = ''
                this.commentId = ''
                this.editId = ''
                this.$refs.ActivityRulesEdit.reset()

                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Observação editada com sucesso!',
                    text: 'Você pode editar as observações criadas sempre que quiser acessando os “Detalhes” dos pacientes.',
                    size: 'sm',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                  },
                })
              })
              .catch(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Erro ao editar a observação',
                    text: 'Ops... Algo deu errado, tente novamente',
                    size: 'sm',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
              .finally(() => {
                this.buttonDisable = false
              })
          }
        })
      }
    },
    showRemoveModal() {
      this.$swal({
        title: '',
        html: '<h3 class="mt-0 text-gray-800 font-weight-bold">Atenção!</h3><p class="text-gray-800">Tem certeza de que deseja excluir a observação?</p>',
        icon: 'warning',
        iconColor: '#D45858',
        showCancelButton: true,
        confirmButtonText: 'Excluir',
        cancelButtonText: 'Cancelar',
        customClass: {
          content: 'p-0',
          actions: 'pb-1',
          confirmButton: 'btn btn-danger mx-1',
          cancelButton: 'btn btn-flat-primary mx-1',
        },
        reverseButtons: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        buttonsStyling: false,
        preConfirm: () => this.remove(),
      })
    },
    remove() {
      const dataForm = {
        bariatric_journey_comment_id: this.commentId,
      }
      this.$store.dispatch('bariatric/removeComment', dataForm)
        .then(() => {
          this.loadData()

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Observação excluída com sucesso!',
              text: 'A observação não faz mais parte dos detalhes do paciente.',
              size: 'sm',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao excluir a observação',
              text: 'Ops... Algo deu errado, tente novamente',
              size: 'sm',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    cancel() {
      this.comment = ''
      this.commentId = ''
    },
    cancelEdit() {
      this.editId = ''
      this.commentId = ''
    },
  },
}
</script>

<style scoped>
.textarea-counter-value {
    background-color: #5A607F;
}

.viewItens .vs__dropdown-menu {
  margin-top: -190px;
}
</style>
