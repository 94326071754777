<template>

  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <b-card-header>
        <b-card-title class="text-gray-800">
          Monitoramento de peso:
        </b-card-title>
      </b-card-header>
      <b-card-body>

        <b-row class="mt-1">
          <div class="col">
            <h5 class="font-weight-bold">
              Data cirurgia:
            </h5>
            <span>{{ surgery && surgery.surgery_date ? formatDateBR(surgery.surgery_date) : '-' }}</span>
          </div>
          <div class="col">
            <h5 class="font-weight-bold">
              Dias após a cirurgia:
            </h5>
            <span>{{ surgery && surgery.surgery_days_past ? surgery.surgery_days_past : '-' }}</span>
          </div>
          <div class="col">
            <h5 class="font-weight-bold">
              Peso perdido desde a cirurgia:
            </h5>
            <span>{{ surgery && surgery.surgery_weight_loss ? `${surgery.surgery_weight_loss} kg` : '-' }}</span>
          </div>
        </b-row>
        <b-row class="justify-content-end mt-2">
          <b-col md="auto">
            <div
              role="group"
              class="form-row form-group align-items-center"
            >
              <label
                for="weightDate"
                class="col-md-auto label-weight-date col-form-label text-gray-500"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-calendar4"
                  viewBox="0 0 16 16"
                >
                  <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v1h14V3a1 1 0 0 0-1-1H2zm13 3H1v9a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V5z" />
                </svg>

                Data inicial/final:
              </label>
              <div class="col">
                <flat-pickr
                  id="weightDate"
                  v-model="weightDate"
                  class="form-control text-center"
                  :config="configDate"
                  @on-change="onWeightDateChange"
                  @on-close="onWeightDateClose"
                />
              </div>
            </div>
          </b-col>
        </b-row>
        <div>
          <bariatric-weight-chart />
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import moment from 'moment'
import {
  BRow,
  BCard,
  BCol,
  BCardHeader,
  BCardTitle,
  BCardBody,
} from 'bootstrap-vue'

import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Portuguese } from 'flatpickr/dist/l10n/pt'

import bariatricWeightChart from './Bariatric-weight-chart.vue'

export default {
  components: {
    BRow,
    BCard,
    BCol,
    BCardHeader,
    BCardTitle,
    BCardBody,

    flatPickr,

    bariatricWeightChart,
  },

  data() {
    return {
      weightDate: `${this.$store.getters['bariatric/bariatricWeightStart']} até ${this.$store.getters['bariatric/bariatricWeightEnd']}`,
      configDate: {
        mode: 'range',
        dateFormat: 'd/m/Y',
        maxDate: 'today',
        locale: Portuguese,
      },
    }
  },

  computed: {
    surgery() {
      return this.$store.getters['bariatric/bariatricSurgery']
    },
  },

  methods: {
    formatDateBR(value) {
      return moment(value).format('DD/MM/YYYY')
    },
    onWeightDateChange(selectedDates, dataString, instance) {
      if (selectedDates.length === 1) {
        /* eslint-disable no-param-reassign */
        instance.config.minDate = moment(selectedDates[0]).format('DD/MM/YYYY')
        instance.config.maxDate = moment(selectedDates[0]).add(30, 'days') >= moment() ? moment().format('DD/MM/YYYY') : moment(selectedDates[0]).add(30, 'days').format('DD/MM/YYYY')
      } else if (selectedDates.length === 2) {
        instance.config.minDate = undefined
        instance.config.maxDate = moment().format('DD/MM/YYYY')

        const weightDateStart = moment(selectedDates[0]).format('DD/MM/YYYY')
        const weightDateEnd = moment(selectedDates[1]).format('DD/MM/YYYY')

        this.$store.commit('bariatric/UPDATE_BARIATRIC_WEIGHT_START', weightDateStart)
        this.$store.commit('bariatric/UPDATE_BARIATRIC_WEIGHT_END', weightDateEnd)
      }
    },
    onWeightDateClose(selectedDates, dataString, instance) {
      if (selectedDates.length === 1) {
        instance.clear()
        /* eslint-disable no-param-reassign */
        instance.config.minDate = null
        instance.config.maxDate = moment().format('DD/MM/YYYY')
      }
    },
  },
}
</script>

<style scoped>
.flatpickr-input {
  min-width: 210px;
}
.label-weight-date > svg{
  margin-right: 5px;
}
</style>
