<template>
  <div>
    <b-card no-body>
      <b-card-body class="p-0">
        <e-charts
          ref="line"
          class="chart-bariatric-weight"
          :height="350"
          autoresize
          :options="chartjsDataBariatricWeight.option"
        />
      </b-card-body>
      <div
        v-if="itemsAlertFilter.length !== 0"
        class="mt-3 bariatric-weight-table"
      >
        <b-table
          :fields="fields"
          :items="itemsAlertFilter"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardBody, BTable,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Portuguese } from 'flatpickr/dist/l10n/pt'
import moment from 'moment'
import 'twix'

import ECharts from 'vue-echarts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/chart/line'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import theme from '@core/components/charts/echart/theme.json'

import { option as chartjsDataBariatricWeight } from './chartjsDataBariatricWeight'

ECharts.registerTheme('theme-color', theme)

export default {
  components: {
    BCard,
    BCardBody,
    BTable,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ECharts,
  },

  data() {
    return {
      chartjsDataBariatricWeight,
      selected: '',

      configDate: {
        mode: 'range',
        dateFormat: 'd/m/Y',
        maxDate: 'today',
        locale: Portuguese,
      },
      fields: [
        {
          key: 'weightGoal',
          label: 'Peso objetivo:',
        },
        {
          key: 'weight',
          label: 'Peso registrado:',
        },
        {
          key: 'weightDiff',
          label: 'Diferença de peso:',
        },
        {
          key: 'bmi',
          label: 'Índice de massa corporal:',
        },
      ],
      itemsAlert: [],
      itemsAlertFilter: [],
    }
  },

  computed: {
    weightDate() {
      return `${this.$store.getters['bariatric/bariatricWeightStart']} até ${this.$store.getters['bariatric/bariatricWeightEnd']}`
    },
  },

  watch: {
    weightDate() {
      this.changeData()
    },
    // eslint-disable-next-line func-names
    '$store.state.bariatric.selectedWeight': async function () {
      this.selected = await this.$store.getters['bariatric/selectedWeight']
      this.loadListAlert()
    },
  },

  mounted() {
    this.changeData()
  },

  methods: {
    changeData() {
      this.itemsAlertFilter = []
      this.$store.commit('bariatric/UPDATE_SELECTED_WEIGHT', '')
      this.$store.commit('bariatric/UPDATE_BARIATRIC_SUGERY', {})

      this.$store.dispatch('bariatric/searchBariatricWeightMonitor', { start_date: this.$store.getters['bariatric/bariatricWeightStart'], end_date: this.$store.getters['bariatric/bariatricWeightEnd'] })
        .then(response => {
          this.loadChart(response)
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    loadChart(data) {
      const bariatricWeightMonitorsInfo = data.data.data.bariatric_weight_monitors_info
      const bariatricWeightGoalInfo = data.data.data.bariatric_weight_goal_info
      const bariatricSurgeryWeightInfo = data.data.data.bariatric_weight_goal_info?.bariatric_surgery_weight_info
      const range = []

      this.$store.commit('bariatric/UPDATE_BARIATRIC_SUGERY', bariatricSurgeryWeightInfo)

      const itr = moment.twix(moment(this.$store.getters['bariatric/bariatricWeightStart'], 'DD/MM/YYYY'),
        moment(this.$store.getters['bariatric/bariatricWeightEnd'], 'DD/MM/YYYY')).iterate('days')
      while (itr.hasNext()) {
        range.push(itr.next().format('DD/MM/YYYY'))
      }

      chartjsDataBariatricWeight.option.xAxis.data = range

      const arrayData = []
      const arrayDataAlert = []
      const arrayAlert = []
      let arrayAdd = true
      let arrayAddAlert = true

      range.forEach(valueRange => {
        arrayAdd = true
        arrayAddAlert = true

        bariatricWeightMonitorsInfo.forEach(valueHealthMonitor => {
          arrayDataAlert.push(bariatricWeightGoalInfo.target_weight)
          const dateHealthMonitorInfo = moment(valueHealthMonitor.date).format('DD/MM/YYYY')

          if (valueRange === dateHealthMonitorInfo) {
            arrayData.push(valueHealthMonitor.weight)

            const listAlert = {
              date: moment(valueHealthMonitor.date).format('DD/MM/YYYY'),
              weightGoal: `${bariatricWeightGoalInfo.target_weight} Kg`,
              weight: `${valueHealthMonitor.weight} Kg`,
              weightDiff: `${valueHealthMonitor.weight_diff} Kg`,
              bmi: `${valueHealthMonitor.bmi}`,
            }

            arrayAlert.unshift(listAlert)
            arrayAddAlert = false

            arrayAdd = false
          }
        })
        if (arrayAdd) {
          arrayData.push(null)
        }
        if (arrayAddAlert) {
          arrayDataAlert.push(null)
        }
      })

      this.itemsAlert = arrayAlert

      const newModelSeries = [
        {
          name: 'Peso',
          data: arrayData,
          smooth: true,
          type: 'line',
          connectNulls: true,
          symbol: 'circle',
          symbolSize: 12,
        },
        {
          name: 'Objetivo',
          data: arrayDataAlert,
          smooth: true,
          type: 'line',
          connectNulls: true,
          symbol: 'circle',
          symbolSize: 0,
          lineStyle: {
            width: 1,
            type: 'dashed',
          },
        },
      ]

      chartjsDataBariatricWeight.option.series = newModelSeries
    },
    async loadListAlert() {
      if (this.selected !== '') {
        const result = await this.itemsAlert.filter(item => item.date === this.selected)
        this.itemsAlertFilter = result
      }
    },
  },
}
</script>

<style>
  .chart-bariatric-weight {
    width: 100%;
  }

  .bariatric-weight-table .table thead th {
    vertical-align: middle;
    font-size: 1rem;
    letter-spacing: initial;
    text-transform: none;
    color: #171725;
  }

  .bariatric-weight-table .table th{
    padding: 1.2rem 2rem;
    border-bottom: 0;
  }

  .bariatric-weight-table .table td {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
</style>
