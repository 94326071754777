<template>
  <validation-observer ref="protocolModelForm">
    <b-form @submit.prevent>
      <b-row>
        <b-col>
          <div v-if="form.optionsExams.length > 0">
            <b-form-group
              label-class="text-gray-800"
              :label="textLabel"
            >
              <div
                v-if="form.optionsExams.length > 0"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <transition-group
                    name="fade"
                    :duration="150"
                    mode="out-in"
                  >
                    <b-form-checkbox
                      v-for="option in form.optionsExams"
                      :key="option.id"
                      v-model="form.selectedExam"
                      :value="option"
                      name="exams"
                      class="mb-1"
                      @change="removeOption(option)"
                    >
                      {{ option.name }}
                    </b-form-checkbox>
                  </transition-group>

                  <template>
                    <b-row>
                      <b-col>
                        <vue-autosuggest
                          v-model="form.currentExamSuggestValue"
                          :suggestions="form.optionsExamSuggest"
                          :limit="10"
                          :input-props="{id:'autosuggest__input', class:'form-control', placeholder:'Adicionar outro exame'}"
                          @selected="onExamSelected"
                          @input="onExamInputChange"
                        >
                          <template slot-scope="{suggestion}">
                            <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
                          </template>
                        </vue-autosuggest>
                      </b-col>
                      <b-col md="3">
                        <transition-group
                          name="fade"
                          :duration="150"
                        >
                          <b-button
                            v-show="form.emptyExamSuggest && form.currentExamSuggestValue.length > 0"
                            key="add-button"
                            variant="primary"
                            class="btn-icon mr-md-1"
                            @click="addExam"
                          >
                            <feather-icon
                              icon="CheckIcon"
                              size="18"
                            />
                          </b-button>
                          <b-button
                            v-show="form.emptyExamSuggest && form.currentExamSuggestValue.length > 0"
                            key="clear-button"
                            variant="danger"
                            class="btn-icon"
                            @click="clearSuggest"
                          >
                            <feather-icon
                              icon="XIcon"
                              size="18"
                            />
                          </b-button>
                        </transition-group>
                      </b-col>
                    </b-row>
                  </template>

                  <small
                    v-if="errors.length > 0"
                    class="text-danger"
                  >
                    Selecione pelo menos um exame para incluir no protocolo.
                  </small>
                </validation-provider>
              </div>
              <template v-else>
                <small class="text-muted">Nenhum exame encontrado.</small>
              </template>
            </b-form-group>

          </div>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  ValidationProvider, ValidationObserver, localize,
} from 'vee-validate'
import { VueAutosuggest } from 'vue-autosuggest'
import {
  BForm, BFormGroup, BButton, BCol, BRow, BFormCheckbox,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BForm,
    BFormGroup,
    BButton,
    BRow,
    BCol,
    BFormCheckbox,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    VueAutosuggest,

    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      action: this.$store.getters['protocolModel/action'],
      textLabel: '',
      form: {
        optionsExams: this.$store.getters['protocolModel/protocolModelExamsIds'],

        selectedExam: [],

        optionsExamSuggest: [],
        emptyExamSuggest: false,
        currentExamSuggestValue: '',
      },
    }
  },

  watch: {
    form: {
      handler(newVal) {
        this.$store.commit('protocolModel/UPDATE_PROTOCOL_MODEL_EXAMS', newVal)
      },
      deep: true,
    },
  },

  async mounted() {
    localize(this.$store.getters['cuido/locale'])
    await this.changeLabel()
    await this.listExams()
  },

  methods: {
    changeLabel() {
      if (this.action === 'detail') {
        this.textLabel = ''
      } else {
        this.textLabel = 'Selecione os exames que deseja incluir neste protocolo:'
      }
    },
    async listExams() {
      await this.$store.dispatch('protocolModel/listExams', { main: true })
        .then(response => {
          const examsInfo = response.data.data.exams_info
          if (examsInfo !== null) {
            const options = []
            const selectedArray = this.form.optionsExams.map(e => e.id)
            examsInfo.forEach(item => {
              if (selectedArray.indexOf(item.id) === -1 && this.action === 'detail') {
                options.push({
                  id: item.id,
                  name: item.name,
                })
              } else if (this.action === 'register' || this.action === 'bariatricRegister') {
                options.push({
                  id: item.id,
                  name: item.name,
                })
              }
            })
            const ids = new Set(options.map(d => d.id))
            this.form.selectedExam = this.form.optionsExams
            if (this.action === 'detail') {
              this.form.optionsExams = [...options]
            } else {
              this.form.optionsExams = [...options, ...this.form.optionsExams.filter(d => !ids.has(d.id))]
            }
          }
        })
    },
    addExam() {
      if (this.form.currentExamSuggestValue.length > 0) {
        const data = { name: this.form.currentExamSuggestValue }
        this.$store.dispatch('protocolModel/addExam', { data })
          .then(response => {
            const examInfo = response.data.data.exam_info
            if (this.action !== 'detail') {
              this.form.optionsExams.push({ id: examInfo.id, name: examInfo.name })
            }
            this.form.selectedExam.push({ id: examInfo.id, name: examInfo.name })

            this.form.currentExamSuggestValue = ''

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Item cadastrado com sucesso',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          })
          .catch(() => {
            this.form.currentExamSuggestValue = ''
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ops... Algo deu errado, tente novamente',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
    onExamInputChange(search) {
      if (search.length < 2) {
        return
      }
      this.$store.dispatch('protocolModel/listExams', { main: false, name: search })
        .then(response => {
          const examsInfo = response.data.data.exams_info
          if (examsInfo !== null && examsInfo.length > 0) {
            const options = []
            examsInfo.forEach(item => {
              options.push({
                id: item.id,
                name: item.name,
              })
            })
            this.form.optionsExamSuggest = [{
              data: options,
            }]
            this.form.emptyExamSuggest = false
          } else {
            this.form.emptyExamSuggest = true
            this.form.optionsExamSuggest = []
          }
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    onExamSelected(option) {
      if (this.form.optionsExams.find(item => item.id === option.item.id)) {
        const item = {
          id: option.item.id,
          name: option.item.name,
        }
        this.form.selectedExam.push(item)
      } else {
        const item = {
          id: option.item.id,
          name: option.item.name,
        }
        this.form.selectedExam.push(item)
        if (this.action !== 'detail') {
          this.form.optionsExams.push(item)
        }
      }
      this.form.currentExamSuggestValue = null
      this.form.optionsExamSuggest = []
    },
    async show(id) {
      await this.$store.dispatch('protocolModel/show', { id })
        .then(response => {
          const protocolModelInfo = response.data.data.bariatric_protocol_model_info
          if (protocolModelInfo !== null) {
            this.protocolModel = {
              id: protocolModelInfo.id,
              name: protocolModelInfo.name,
              healthSpecialists: protocolModelInfo.health_specialists,
              exams: protocolModelInfo.exams,
            }
          }
        })
    },
    removeOption(value) {
      if (this.action === 'detail') {
        const index = this.form.optionsExams.indexOf(value)
        this.form.optionsExams.splice(index, 1)
      }
    },
    clearSuggest() {
      this.form.currentHealthSpecialistSuggestValue = ''
      this.form.currentExamSuggestValue = ''
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest';
</style>
