<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <validation-observer ref="specialistRules">
      <b-form>
        <h5 class="text-gray-800">
          Autorização da cirurgia:
        </h5>
        <b-row class="mt-2">
          <div
            class="col-auto form-group"
            style="margin-top: 10px"
          >
            <label for="specialist-status">Status:</label>
          </div>
          <div
            class="col-3"
          >
            <validation-provider
              #default="{ errors }"
              name="status"
              rules="required"
            >
              <v-select
                v-model="status"
                :options="statusOption"
                :reduce="option => option.id"
                :clearable="false"
              >
                <template v-slot:option="option">
                  <div
                    style="padding: 2px 0;"
                    v-html="option.label"
                  />
                </template>
                <template v-slot:selected-option="option">
                  <div
                    v-html="option.label"
                  />
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </div>
        </b-row>
        <div class="mt-1">
          <validation-provider
            #default="{ errors }"
            name="data do pedido da cirurgia"
            rules="required"
          >
            <div class="col-4 p-0">
              <b-form-group
                label="Data do pedido da cirurgia:"
                label-for="surgeryOrderDate"
                class="mb-0"
              >
                <b-input-group class="input-group-merge">
                  <flat-pickr
                    id="surgeryOrderDate"
                    v-model="surgeryOrderDate"
                    class="form-control"
                    :config="configDate"
                    placeholder="Selecione"
                  />
                  <b-input-group-append>
                    <b-input-group-text>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-calendar4"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v1h14V3a1 1 0 0 0-1-1H2zm13 3H1v9a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V5z" />
                      </svg>
                    </b-input-group-text>
                  </b-input-group-append>
                </b-input-group>

              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </div>
          </validation-provider>
        </div>
        <div class="mt-2 mb-3">
          <validation-provider
            #default="{ errors }"
            name="data da autorização"
            rules=""
          >
            <div class="col-4 p-0">
              <b-form-group
                label="Data da autorização:"
                label-for="authorizationDate"
                class="mb-0"
              >

                <b-input-group class="input-group-merge">
                  <flat-pickr
                    id="authorizationDate"
                    v-model="authorizationDate"
                    class="form-control"
                    :config="configDate"
                    placeholder="Selecione"
                  />
                  <b-input-group-append>
                    <b-input-group-text>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-calendar4"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v1h14V3a1 1 0 0 0-1-1H2zm13 3H1v9a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V5z" />
                      </svg>
                    </b-input-group-text>
                  </b-input-group-append>
                </b-input-group>

              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </div>
          </validation-provider>
        </div>
        <div class="mb-2 ml-1">
          <b-row>
            <b-form-checkbox
              v-model="surgeryRefused"
              value="true"
            >
              A autorização da cirurgia foi recusada.
            </b-form-checkbox>
          </b-row>
        </div>
        <b-row>
          <b-col
            cols="6"
            class="mt-3"
          >
            <b-button
              type="button"
              variant="outline-primary"
              title="Cancelar"
              @click="cancel"
            >
              Cancelar
            </b-button>
            <b-button
              type="button"
              variant="primary"
              class="ml-2"
              :disabled="buttonDisable"
              title="Salvar"
              @click="save"
            >
              Salvar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BButton, BForm, BFormGroup, BFormCheckbox, BInputGroup, BInputGroupAppend,
  BInputGroupText,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Portuguese } from 'flatpickr/dist/l10n/pt'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupText,

    vSelect,
    flatPickr,

    ValidationProvider,
    ValidationObserver,

  },

  directives: {
    Ripple,
  },

  data() {
    return {
      status: 'PENDING',
      statusOption: [
        {
          id: 'PENDING',
          label: '<span class="badge badge-bariatric badge-warning">Em aberto</span>',
        },
        {
          id: 'DONE',
          label: '<span class="badge badge-bariatric badge-success">Concluído</span>',
        },
      ],

      surgeryOrderDate: '',
      authorizationDate: '',
      surgeryRefused: 'false',
      buttonDisable: false,

      configDate: {
        mode: 'single',
        dateFormat: 'd/m/Y',
        locale: Portuguese,
        minDate: moment(this.$store.getters['bariatric/firstAppointmentDate']).format('DD/MM/YYYY'),
      },
    }
  },

  created() {
    this.loadData()
  },

  methods: {
    loadData() {
      const bariatricData = this.$store.getters['bariatric/bariatricMember']
      this.$store.dispatch('bariatric/showSurgeryRequest', { bariatric_journey_id: bariatricData.bariatric_journey_id })
        .then(response => {
          const data = response.data.data.surgery_requests_info
          if (data.length > 0) {
            this.status = data[0].status
            this.surgeryOrderDate = moment(data[0].request_date).format('DD/MM/YYYY')
            this.authorizationDate = moment(data[0].request_approval_date).format('DD/MM/YYYY')
            this.surgeryRefused = data[0].approved
          }
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    changeStatusJourney() {
      if (this.status === 'PENDING') {
        this.$store.commit('bariatric/UPDATE_JOURNEY_PROGRESS', { key: 'bariatric_journey_surgery_request', value: false })
      } else {
        this.$store.commit('bariatric/UPDATE_JOURNEY_PROGRESS', { key: 'bariatric_journey_surgery_request', value: true })
      }
    },
    save() {
      this.$refs.specialistRules.validate().then(success => {
        if (success) {
          this.buttonDisable = true
          const bariatricData = this.$store.getters['bariatric/bariatricMember']
          const dataForm = {
            bariatric_journey_id: bariatricData.bariatric_journey_id,
            surgery_request_info: {
              status: this.status,
              request_date: moment(this.surgeryOrderDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
              approved: (this.surgeryRefused === 'true'),
            },
          }
          if (this.authorizationDate !== '') {
            dataForm.surgery_request_info.request_approval_date = moment(this.authorizationDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
          }
          this.$store.dispatch('bariatric/saveSurgeryRequest', dataForm)
            .then(() => {
              this.changeStatusJourney()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Dados do paciente salvos com sucesso!',
                  text: 'Você pode editar os dados sempre que quiser acessando os “Detalhes” dos pacientes.',
                  size: 'sm',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
              this.$store.commit('survey/UPDATE_BARIATRIC_SURVEY', true)
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Erro ao salvar os dados do paciente',
                  text: 'Ops... Algo deu errado, tente novamente',
                  size: 'sm',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
            .finally(() => {
              this.buttonDisable = false
            })
        }
      })
    },
    cancel() {
      this.$router.push('/bariatric')
    },
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
