<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <b-card-header>
        <b-card-title class="text-gray-800">
          Detalhes da jornada:
        </b-card-title>
      </b-card-header>
      <b-card-body>
        <b-tabs
          content-class="pt-2"
          fill
        >
          <b-tab
            title="Equipe multidisciplinar"
            title-link-class="h5 pb-1 font-weight-bold"
            :active="tabId==='specialist'? true : false"
            @click="alterTab('specialist')"
          >
            <template>
              <bariatric-tab-specialist v-if="tabId === 'specialist'" />
            </template>
          </b-tab>
          <b-tab
            title="Exames"
            title-link-class="h5 pb-1 font-weight-bold"
            :active="tabId==='exam'? true : false"
            @click="alterTab('exam')"
          >
            <template>
              <bariatric-tab-exam v-if="tabId === 'exam'" />
            </template>
          </b-tab>
          <b-tab
            v-if="dataBariatric.source_request === 'HEALTH_INSURANCE'"
            title="Autorização da cirurgia"
            title-link-class="h5 pb-1 font-weight-bold"
            :active="tabId==='authorization'? true : false"
            @click="alterTab('authorization')"
          >
            <template>
              <bariatric-tab-surgery-authorization v-if="tabId === 'authorization'" />
            </template>
          </b-tab>
          <b-tab
            title="Realização da cirurgia"
            title-link-class="h5 pb-1 font-weight-bold"
            :active="tabId==='realized'? true : false"
            @click="alterTab('realized')"
          >
            <template>
              <bariatric-tab-surgery-realized v-if="tabId === 'realized'" />
            </template>
          </b-tab>
          <b-tab
            title="Observações"
            title-link-class="h5 pb-1 font-weight-bold"
            :active="tabId==='activities'? true : false"
            @click="alterTab('activities')"
          >
            <template>
              <bariatric-tab-activities v-if="tabId === 'activities'" />
            </template>
          </b-tab>
        </b-tabs>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BTab, BTabs,
} from 'bootstrap-vue'
import bariatricTabSpecialist from './Bariatric-tab-specialist.vue'
import bariatricTabExam from './Bariatric-tab-exam.vue'
import bariatricTabSurgeryAuthorization from './Bariatric-tab-surgery-authorization.vue'
import bariatricTabSurgeryRealized from './Bariatric-tab-surgery-realized.vue'
import bariatricTabActivities from './Bariatric-tab-activities.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BTab,
    BTabs,

    bariatricTabSpecialist,
    bariatricTabExam,
    bariatricTabSurgeryAuthorization,
    bariatricTabSurgeryRealized,
    bariatricTabActivities,

  },

  data() {
    return {
      tabId: 'specialist',
      dataBariatric: this.$store.getters['bariatric/bariatric'],
    }
  },

  watch: {
    // eslint-disable-next-line func-names
    '$store.state.bariatric.detailTab': function () {
      this.tabId = this.$store.getters['bariatric/detailTab']
    },
  },

  methods: {
    alterTab(value) {
      this.$store.commit('bariatric/UPDATE_DETAIL_TAB', value)
    },
  },
}
</script>
