<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <validation-observer ref="specialistRules">
      <b-form>
        <h5 class="text-gray-800">
          Realização da cirurgia:
        </h5>
        <div>
          <b-row class="mt-2">
            <div
              class="col-auto form-group"
              style="margin-top: 10px"
            >
              <label for="specialist-status">Status:</label>
            </div>
            <div
              class="col-3"
            >
              <validation-provider
                #default="{ errors }"
                name="status"
                rules="required"
              >
                <v-select
                  v-model="status"
                  :options="statusOption"
                  :reduce="option => option.id"
                  :clearable="false"
                  @option:selected="changeCheckbox"
                >
                  <template v-slot:option="option">
                    <div
                      style="padding: 2px 0;"
                      v-html="option.label"
                    />
                  </template>
                  <template v-slot:selected-option="option">
                    <div
                      v-html="option.label"
                    />
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </b-row>
        </div>

        <div class="mt-1">
          <validation-provider
            #default="{ errors }"
            name="data agendada para cirurgia"
            rules="required"
          >
            <b-form-group
              label="Data agendada para cirurgia:"
              class="mb-0 col-4 p-0"
            >
              <b-input-group class="input-group-merge">
                <flat-pickr
                  v-model="surgeryOrderDate"
                  class="form-control"
                  :config="configDate"
                  placeholder="Selecione"
                />
                <b-input-group-append>
                  <b-input-group-text>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-calendar4"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v1h14V3a1 1 0 0 0-1-1H2zm13 3H1v9a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V5z" />
                    </svg>
                  </b-input-group-text>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </div>

        <div class="mt-2">
          <b-row>
            <b-col class="col-6">
              <b-form-group
                label="Nome do hospital:"
                label-for="hospitalName"
              >
                <validation-provider
                  #default="{ errors }"
                  name="nome do hospital"
                  rules="required"
                >
                  <b-form-input
                    v-model="hospitalName"
                    placeholder="Ex: Hospital Galileu Galilei"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label-for="tipo de cirurgia"
                label="Tipo de cirurgia:"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="tipo de cirurgia"
                  rules="required"
                >
                  <v-select
                    v-model="typeSurgery"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="typeSurgeryOption"
                    :reduce="typeSurgeryOption => typeSurgeryOption.id"
                    placeholder="Selecione"
                    class="style-select"
                  >
                    <div slot="no-options">
                      Nenhum registro encontrado.
                    </div>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div class="my-1 ml-1">
          <b-row>
            <b-form-checkbox
              v-model="surgeryPerformed"
              value="true"
              @change="changeSelect()"
            >
              A cirurgia foi realizada.
            </b-form-checkbox>
          </b-row>
        </div>
        <b-row>
          <b-col
            cols="6"
            class="mt-3"
          >
            <b-button
              type="button"
              variant="outline-primary"
              @click="cancel"
            >
              Cancelar
            </b-button>
            <b-button
              type="button"
              variant="primary"
              class="ml-2"
              :disabled="buttonDisable"
              @click="save"
            >
              Salvar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BButton, BForm, BFormGroup, BFormCheckbox, BFormInput,
  BInputGroup, BInputGroupAppend, BInputGroupText,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Portuguese } from 'flatpickr/dist/l10n/pt'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupText,

    vSelect,
    flatPickr,

    ValidationProvider,
    ValidationObserver,

  },

  directives: {
    Ripple,
  },

  data() {
    return {
      status: 'PENDING',
      statusOption: [
        {
          id: 'PENDING',
          label: '<span class="badge badge-bariatric badge-warning">Em aberto</span>',
        },
        {
          id: 'DONE',
          label: '<span class="badge badge-bariatric badge-success">Concluído</span>',
        },
      ],

      surgeryOrderDate: '',
      surgeryPerformed: false,
      hospitalName: '',

      typeSurgery: '',
      typeSurgeryOption: [],

      buttonDisable: false,

      authorizationDate: '',

      configDate: {
        mode: 'single',
        dateFormat: 'd/m/Y',
        locale: Portuguese,
        minDate: moment(this.$store.getters['bariatric/firstAppointmentDate']).format('DD/MM/YYYY'),
      },
    }
  },

  created() {
    this.loadTypeOption()
    this.loadData()
  },

  mounted() {
    this.loadData()
  },

  methods: {
    loadData() {
      const bariatricData = this.$store.getters['bariatric/bariatricMember']
      this.$store.dispatch('bariatric/showSurgery', { bariatric_journey_id: bariatricData.bariatric_journey_id })
        .then(response => {
          const data = response.data.data.surgeries_info
          if (data.length > 0) {
            this.status = data[0].status
            this.surgeryOrderDate = moment(data[0].schedule_date).format('DD/MM/YYYY')
            this.hospitalName = data[0].hospital_name
            this.typeSurgery = data[0].surgery_type_id
            this.surgeryPerformed = data[0].surgery_performed
          }
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
      this.$store.dispatch('bariatric/showSurgeryRequest', { bariatric_journey_id: bariatricData.bariatric_journey_id })
        .then(response => {
          const data = response.data.data.surgery_requests_info
          if (data.length > 0) {
            this.authorizationDate = data[0].request_approval_date
          }
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    loadTypeOption() {
      this.$store.dispatch('bariatric/surgeryTypeList')
        .then(response => {
          this.typeSurgeryOption = response.data.data.surgery_types_info
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    changeCheckbox() {
      if (this.status === 'DONE') {
        this.surgeryPerformed = 'true'
      } else {
        this.surgeryPerformed = 'false'
      }
    },
    changeSelect() {
      if (this.surgeryPerformed === 'true') {
        this.status = 'DONE'
      } else {
        this.status = 'PENDING'
      }
    },
    changeStatusJourney() {
      if (this.status === 'PENDING') {
        this.$store.commit('bariatric/UPDATE_JOURNEY_PROGRESS', { key: 'bariatric_journey_surgery', value: false })
      } else {
        this.$store.commit('bariatric/UPDATE_JOURNEY_PROGRESS', { key: 'bariatric_journey_surgery', value: true })
      }
    },
    save() {
      this.$refs.specialistRules.validate().then(success => {
        if (success) {
          // this.buttonDisable = true
          const bariatricMemberData = this.$store.getters['bariatric/bariatricMember']
          const bariatricData = this.$store.getters['bariatric/bariatric']
          const dataForm = {
            bariatric_journey_id: bariatricMemberData.bariatric_journey_id,
            surgery_info: {
              status: this.status,
              hospital_name: this.hospitalName,
              surgery_type_id: this.typeSurgery,
              surgery_performed: (this.status === 'DONE'),
            },
          }
          if (this.surgeryOrderDate !== '') {
            dataForm.surgery_info.schedule_date = moment(this.surgeryOrderDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
          }

          if (bariatricData.source_request === 'HEALTH_INSURANCE' && this.status === 'DONE' && (this.authorizationDate === '' || this.authorizationDate === null)) {
            this.$swal({
              title: 'Não foi possível concluir essa etapa.',
              text: 'Para concluir a etapa de realização da cirurgia, é necessário concluir primeiro a etapa de autorização da cirurgia.',
              icon: 'warning',
              showCancelButton: true,
              reverseButtons: true,
              confirmButtonText: 'Concluir etapa',
              cancelButtonText: 'Cancelar',
              customClass: {
                confirmButton: 'btn btn-warning ml-1',
                cancelButton: 'btn text-primary',
              },
              buttonsStyling: false,
            })
              .then(result => {
                if (result.isConfirmed) {
                  this.$store.commit('bariatric/UPDATE_DETAIL_TAB', 'authorization')
                }
              })
          } else {
            this.saveData(dataForm)
          }
          this.buttonDisable = false
        }
      })
    },
    cancel() {
      this.$router.push('/bariatric')
    },
    saveData(dataForm) {
      this.$store.dispatch('bariatric/saveSurgery', dataForm)
        .then(() => {
          this.changeStatusJourney()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Dados do paciente salvos com sucesso!',
              text: 'Você pode editar os dados sempre que quiser acessando os “Detalhes” dos pacientes.',
              size: 'sm',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          this.$store.commit('survey/UPDATE_BARIATRIC_SURVEY', true)
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao salvar os dados do paciente',
              text: 'Ops... Algo deu errado, tente novamente',
              size: 'sm',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.buttonDisable = false
        })
    },
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped >
.style-select::v-deep .vs__search::placeholder {
  color: #b8c2cc;
}
</style>
