<template>
  <div v-if="showForm">
    <b-card-group deck>
      <b-card
        class="col-5"
        style="min-height:180px"
      >
        <b-row class="align-items-center">
          <b-col
            class="col-6"
          >
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="50"
                  :src="data.member_name"
                  :text="avatarText(data.member_name)"
                  variant="primary"
                  class="d-none d-lg-block"
                />
              </template>
              <h5 class="m-0">
                {{ data.member_name }}
              </h5>
              <template v-if="data.member_birthdate">
                <small>{{ formatDateBR(data.member_birthdate) }} - {{ data.member_age }} anos</small>
              </template>
            </b-media>
          </b-col>
          <b-col class="col-4">
            <h5 class="mb-0">
              Dias após a primeira consulta
            </h5>
          </b-col>

          <b-col class="col-2 d-flex justify-content-center align-items-center">
            <div
              v-if="data.days_past_first_appointment !== null"
            >
              <b-badge
                :variant="`light-${resolveBariatricDaysVariant(data.days_past_first_appointment)}`"
                class="text-capitalize pl-2 pr-2"
              >
                {{ formatDay(data.days_past_first_appointment) }}
              </b-badge>
            </div>
          </b-col>

        </b-row>
      </b-card>
      <b-card>
        <b-row>
          <b-col class="col-auto">
            <h5>
              Progresso da jornada
            </h5>
          </b-col>
          <b-col class="col-2">
            <div class="text-right">
              {{ progressConcluded(data.journey_progress) }}
            </div>
          </b-col>
          <b-col class="col-2 pl-0 pr-0">
            <b-progress
              :value="porcentConclued"
              class="p-0"
            />
          </b-col>
        </b-row>
        <b-row
          v-if="showProgress"
          class="mt-2"
        >
          <b-col class="text-center">
            <feather-icon
              :icon="stroke[0] === '#808080' ? 'ClockIcon' : 'CheckCircleIcon'"
              :stroke="stroke[0]"
              size="25"
            />
            <small class="mt-1 d-block">
              Primeira consulta
            </small>
          </b-col>
          <b-col class="text-center">
            <feather-icon
              :icon="stroke[1] === '#808080' ? 'ClockIcon' : 'CheckCircleIcon'"
              :stroke="stroke[1]"
              size="25"
            />
            <small class="mt-1 d-block">
              Compartilhar com cirurgião
            </small>
          </b-col>
          <b-col class="text-center">
            <feather-icon
              :icon="stroke[2] === '#808080' ? 'ClockIcon' : 'CheckCircleIcon'"
              :stroke="stroke[2]"
              size="25"
            />
            <small class="mt-1 d-block">
              Equipe multidisciplinar
            </small>
          </b-col>
          <b-col class="text-center">
            <feather-icon
              :icon="stroke[3] === '#808080' ? 'ClockIcon' : 'CheckCircleIcon'"
              :stroke="stroke[3]"
              size="25"
            />
            <small class="mt-1 d-block">
              Realização de exames
            </small>
          </b-col>
          <b-col
            v-show="dataBariatric.source_request === 'HEALTH_INSURANCE'"
            class="text-center"
          >
            <feather-icon
              :icon="stroke[4] === '#808080' ? 'ClockIcon' : 'CheckCircleIcon'"
              :stroke="stroke[4]"
              size="25"
            />
            <small class="mt-1 d-block">
              Autorização da cirurgia
            </small>
          </b-col>
          <b-col class="text-center">
            <feather-icon
              :icon="stroke[5] === '#808080' ? 'ClockIcon' : 'CheckCircleIcon'"
              :stroke="stroke[5]"
              size="25"
            />
            <small class="mt-1 d-block">
              Realização da cirurgia
            </small>
          </b-col>
        </b-row>
      </b-card>
    </b-card-group>

    <b-row class="mt-3">
      <b-col class="bariatric-menu-aside mt-1 pr-0">
        <b-button
          class="btn mt-1 w-100 text-gray-800 pl-0 pr-0 btn-flat text-center"
          variant="flat"
          :class="{active: activeBtn === 'patientData' }"
          @click="activeBtn = 'patientData'"
        >
          <feather-icon
            icon="UserIcon"
            size="16"
            style="margin-right: 5px;"
          />
          <span class="align-middle font-weight-bold">Dados do paciente</span>
        </b-button>
        <b-button
          class="btn mt-1 w-100 text-gray-800 pl-0 pr-0 btn-flat text-center"
          variant="flat"
          :class="{active: activeBtn === 'journeyDetails' }"
          @click="activeBtn = 'journeyDetails'"
        >
          <feather-icon
            icon="FlagIcon"
            size="16"
            style="margin-right: 5px;"
          />
          <span class="align-middle font-weight-bold">Detalhes da jornada</span>
        </b-button>
        <b-button
          class="btn mt-1 w-100 text-gray-800 pl-0 pr-0 btn-flat text-center"
          variant="flat"
          :class="{active: activeBtn === 'weightMonitoring' }"
          @click="activeBtn = 'weightMonitoring'"
        >
          <b-img
            v-if="activeBtn === 'weightMonitoring'"
            src="@/assets/images/icons/icone-monitor-white.svg"
            width="14px"
          />
          <b-img
            v-else
            src="@/assets/images/icons/icone-monitor-dark.svg"
            width="14px"
          />
          <span style="margin: 5px;">
            Monitoramento de peso
          </span>
        </b-button>
      </b-col>

      <b-col class="col-10">
        <b-card>
          <bariatric-patient
            v-if="activeBtn === 'patientData'"
          />
          <bariatric-journey
            v-if="activeBtn === 'journeyDetails'"
          />
          <bariatric-weight
            v-if="activeBtn === 'weightMonitoring'"
          />
        </b-card>
      </b-col>

    </b-row>
    <BariatricModal />
  </div>
</template>

<script>
import {
  BCard, BCol, BAvatar, BMedia, BRow, BButton, BCardGroup, BBadge, BProgress, BImg,
} from 'bootstrap-vue'
import BariatricModal from '@/layouts/components/surveys/BariatricModal.vue'
// import { mapGetters } from 'vuex'
import avatarText from '@/utils/filter'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import bariatricPatient from './Bariatric-patient.vue'
import bariatricJourney from './Bariatric-journey.vue'
import bariatricWeight from './Bariatric-weight.vue'

export default {
  components: {
    BCard,
    BCol,
    BAvatar,
    BMedia,
    BRow,
    BButton,
    BCardGroup,
    BBadge,
    BProgress,
    BImg,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,

    bariatricPatient,
    bariatricJourney,
    bariatricWeight,
    BariatricModal,

  },

  data() {
    return {
      showForm: false,
      showProgress: true,
      data: '',
      dataBariatric: this.$store.getters['bariatric/bariatric'],
      patient: '',
      activeBtn: 'patientData',
      stroke: ['#808080', '#808080', '#808080', '#808080', '#808080', '#808080'],
      optionsCare: '',
      porcentConclued: '',

      avatarText,
    }
  },

  watch: {
    // eslint-disable-next-line func-names
    '$store.state.bariatric.bariatric': function () {
      this.showProgress = false
      this.dataBariatric = this.$store.state.bariatric.bariatric
      this.showProgress = true
    },
    activeBtn() {
      this.showJourneyProgress()
    },
  },

  async beforeMount() {
    this.$store.commit('protocolModel/UPDATE_ACTION', 'detail')
    await this.loadData()
    await this.showJourneyProgress()
  },

  methods: {
    async loadData() {
      this.showProgress = false
      this.showForm = false
      const bariatricJourneyId = await this.$store.getters['bariatric/bariatricJourneyId']
      this.$store.dispatch('bariatric/showBariatric', bariatricJourneyId)
        .then(async response => {
          const bariatricData = await response.data.data.bariatric_journey_info
          this.$store.commit('bariatric/UPDATE_BARIATRIC', bariatricData)
          this.dataBariatric = bariatricData
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })

      this.data = await this.$store.getters['bariatric/bariatricMember']
      this.showProgress = true
      this.showForm = true
    },
    async showJourneyProgress() {
      const bariatricJourneyId = await this.$store.getters['bariatric/bariatricJourneyId']
      this.$store.dispatch('bariatric/showJourneyProgress', bariatricJourneyId)
        .then(async response => {
          this.data.journey_progress = await response.data.data.journey_progress_info
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    formatDateBR(value) {
      return moment(value).format('DD/MM/YYYY')
    },
    formatString(value) {
      if (value !== undefined && value !== null) {
        const lengthArray = value.length
        let string = ''

        if (lengthArray > 0) {
          value.forEach((valueArray, index) => {
            string += valueArray.name
            if (index < lengthArray - 2) {
              string += ', '
            } else if (index === lengthArray - 2) {
              string += ' e '
            }
          })
        } else {
          string = '-'
        }
        return string
      }
      return ''
    },
    progressConcluded(progress) {
      const progressValue = Object.values(progress)
      let concluded = 0
      progressValue.forEach((value, index) => {
        if (value === true) {
          concluded += 1
          this.stroke[index] = '#28C76F'
        } else {
          this.stroke[index] = '#808080'
        }
      })

      if (concluded > 0) {
        this.featherShow = true
      } else {
        this.featherShow = false
      }

      if (this.dataBariatric.source_request === 'HEALTH_INSURANCE') {
        this.porcentConclued = ((concluded * 100) / 6)
        return `${concluded}/6`
      }
      this.porcentConclued = ((concluded * 100) / 5)
      return `${concluded}/5`
    },
    resolveBariatricDaysVariant(days) {
      if (days < 30) return 'blue'
      if (days > 31 && days < 60) return 'green'
      if (days > 61 && days < 90) return 'orange'
      return 'orange-2'
    },
    formatDay(value) {
      if (value === 1) {
        return `${value}`
      }
      return `${value}`
    },
  },
}
</script>

<style scoped>
.text {
  font-size: 11px;
}

.active{
  background-color:#3279EB;
  color:#fff;
  box-shadow: 0px 4px 18px #3279EBA6;
}

[dir] .badge.badge-light-blue {
  background-color: #56B4E9;
}

.badge.badge-light-blue {
  color: #fff !important;
    font-size: 1.5rem;

}

[dir] .badge.badge-light-green {
  background-color: #109E73;
}

.badge.badge-light-green {
  color: #fff !important;
    font-size: 1.5rem;

}

[dir] .badge.badge-light-orange {
  background-color: #E69F27;
}

.badge.badge-light-orange {
  color: #fff !important;
    font-size: 1.5rem;

}

[dir] .badge.badge-light-orange-2 {
  background-color: #D55E00;
}

.badge.badge-light-orange-2 {
  color: #fff !important;
  font-size: 1.5rem;
}

.bariatric-menu-aside .btn-flat{
  max-width: 250px;
}
</style>
