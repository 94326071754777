<template>
  <b-modal
    v-model="show"
    centered
    hide-header
    footer-class="pt-0 border-0 justify-content-center"
    content-class="p-2"
  >
    <b-card-text class="text-center">
      <b-img
        src="@/assets/images/icons/bari-plus.svg"
        width="130"
        class="mb-3"
      />
      <div class="text-blue-400 h4">
        <strong>{{ title }}</strong>
      </div>
      <div class="my-1 text-gray-800 h5">
        Participe da nossa pesquisa e nos ajude a evoluir cada vez mais nossos serviços.
      </div>
    </b-card-text>
    <template #modal-footer>
      <b-button
        variant="flat-primary"
        @click="cancel"
      >
        {{ cancelTitle }}
      </b-button>

      <b-button
        variant="primary"
        :disabled="disable"
        @click="answer"
      >
        <b-spinner
          v-show="loading"
          small
        />
        Responder agora
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal, BCardText, BImg, BButton, BSpinner,
} from 'bootstrap-vue'

export default ({
  components: {
    BModal, BCardText, BImg, BButton, BSpinner,
  },
  data() {
    return {
      id: '',
      link: '',
      title: '',

      show: false,
      disable: false,
      loading: false,
      cancelTitle: 'Responder depois',
    }
  },

  watch: {
    // eslint-disable-next-line func-names
    '$store.state.survey.bariatric': async function () {
      this.initialize()
    },
  },

  created() {
    this.initialize()
  },

  methods: {
    initialize() {
      const bariatricSurvey = this.$store.getters['survey/bariatric']
      if (bariatricSurvey) {
        this.$store.dispatch('survey/show', { identifier: 'obesity_bariatric_nps' })
          .then(response => {
            const { data } = response.data
            if (Object.keys(data.survey_info).length > 0) {
              this.id = data.survey_info.id
              this.title = data.survey_info.title
              this.link = data.survey_info.link

              this.checkEligibility()
            }
          })
      }
    },
    checkEligibility() {
      const currentTimestamp = Date.now()
      const twentyFourHoursInMs = 24 * 60 * 60 * 1000
      const replySurvey = Number(localStorage.getItem(`replySurvey${this.id}`))

      if (replySurvey) {
        this.cancelTitle = 'Não perguntar mais'
      }

      if ((currentTimestamp - replySurvey) >= twentyFourHoursInMs) {
        setTimeout(() => {
          this.show = true
        }, 2000)
      }
    },
    answer() {
      this.disable = true
      this.loading = true
      this.$store.dispatch('survey/answer', { survey_id: this.id, unsubscribed: false })
        .then(() => {
          window.open(this.link, '_blank').focus()
        })
        .finally(() => {
          this.show = false
          this.disable = false
          this.loading = false
        })
    },
    cancel() {
      const replyBariatricSurvey = localStorage.getItem(`replySurvey${this.id}`)

      if (replyBariatricSurvey) {
        this.$store.dispatch('survey/answer', { survey_id: this.id, unsubscribed: true })
          .then(() => {
            localStorage.removeItem(`replySurvey${this.id}`)
          })
          .finally(() => {
            this.show = false
          })
      } else {
        this.show = false
        const currentTimestamp = Date.now()
        localStorage.setItem(`replySurvey${this.id}`, currentTimestamp)
      }
    },
  },
})
</script>
